import React, { Component } from "react";
import SignedRoute from "../../common/signedRoute";
import { IconButton, Typography, TextField, Tooltip } from "@material-ui/core";
import {
  AddCircleOutline,
  EditOutlined,
  DeleteOutline,
  MoreHorizOutlined,
  VisibilityOutlined,
  Add
} from "@material-ui/icons";

import {
  getCourseLabel,
  addData,
  editData,
  getMyLessons
} from "../../../backend-communication/lessons-controller";

import { getData as getCourses } from "../../admin/lessons/courses/courses-controller";

import DialogCreator from "../../common/dialog/dialogCreator";
import DialogsInfo from "../../common/dialogsInfo";
import CardManagement from "../../common/dynamicCardManagement/cardManagement";
import styles from "./mylessons-styles";
import { withStyles } from "@material-ui/styles";
import AutoFitImage from "react-image-autofit-frame";
import SelectTextfield from "../../common/autocomplete/select/selectTextfield";

import history from "../../common/history";
import { cancelRequests } from "../../../backend-communication/cancel-axios-token";
const dialogs = new DialogsInfo();

class MyLessons extends Component {
  state = {
    el: { id: "", course: "", imageURL: "" },
    rows: [],
    courses: [],
    course: null,
    pageLoading: true,
    loading: false,
    shareCode: {},
    copied: false,
    dialogOpen: false,
    dialogAction: null,
    dialogContent: () => this.dialogForm(),
    dialog: dialogs.genericDialog("empty")
  };

  start = () => {
    getMyLessons(null, (err, rows) =>
      this.setState({ rows, pageLoading: false })
    );
    getCourses(null, courses => {
      this.setState({ courses });
    });
  };

  onChangeCourse = event => {
    let el = { ...this.state.el };
    el.courseID = event.target.value;
    el.course = getCourseLabel(el.courseID, this.state.courses);
    this.setState({ el });
  };

  onChangeType = event => {
    let el = { ...this.state.el };
    el.type = event.target.value;
    this.setState({ el });
  };

  onElementTextChange = (e, variable) => {
    let el = { ...this.state.el };
    el[variable] = e.target.value;
    this.setState({ el });
  };

  openDialog = () => {
    this.setState({ dialogOpen: true });
  };

  closeDialog = () => {
    this.setState({ dialogOpen: false });
  };

  startAdd = el => {
    this.setState({ el: { name: "", imageURL: "" }, dialogOpen: true });
  };

  startEdit = el => {
    this.setState({ el, dialogOpen: true });
  };
  onAdd = () => {
    this.setState({ loading: true });
    addData({ ...this.state.el }, (err, el) => {
      this.setState({ rows: [...this.state.rows, el], loading: false });
    });
  };

  onEdit = () => {
    this.setState({ loading: true });
    editData(this.state.el.id, this.state.el, (err, el) => {
      let rows = [...this.state.rows];
      rows = rows.map(i => {
        if (i.id === el.id) {
          return el;
        }
        return i;
      });

      this.setState({ rows, loading: false });
    });
  };
  componentWillUnmount() {
    cancelRequests("unmounting");
  }

  render() {
    const { classes } = this.props;
    const { rows } = this.state;
    return (
      <React.Fragment>
        <SignedRoute role="public" executeOnValid={this.start}>
          <Typography variant="h5" align={"left"} className={classes.title}>
            Lecciones
            <IconButton
              className={classes.title}
              disabled={this.state.pageLoading}
              onClick={() => {
                {
                  this.setState(
                    {
                      dialog: dialogs.addDialog("lección"),
                      dialogContent: this.dialogForm,
                      dialogAction: this.onAdd
                    },
                    () => {
                      this.startAdd();
                    }
                  );
                }
              }}
            >
              <AddCircleOutline />
            </IconButton>
          </Typography>
          <br />
          <CardManagement
            cols={5}
            titleFieldName={"lesson"}
            thumbnailFieldName={"imageURL"}
            rows={rows}
            cardActions={this.renderExtras}
            blur={3}
            loading={this.state.pageLoading}
          />
          <DialogCreator
            open={this.state.dialogOpen}
            dialogInfo={this.state.dialog}
            dialogContent={this.state.dialogContent}
            handleClose={this.closeDialog}
            handleAction={this.state.dialogAction}
          />
        </SignedRoute>
      </React.Fragment>
    );
  }
  renderExtras = el => {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <Tooltip title={"Crear"}>
          <IconButton
            onClick={() => {
              this.props.moreAction(el);
            }}
          >
            <Add className={classes.extras} />
          </IconButton>
        </Tooltip>
        <Tooltip title={"Editar"}>
          <IconButton
            onClick={() => {
              this.setState(
                {
                  dialog: dialogs.editDialog("lección"),
                  dialogContent: this.dialogForm,
                  dialogAction: this.onEdit
                },
                () => {
                  this.startEdit(el);
                }
              );
            }}
          >
            <EditOutlined className={classes.extras} />
          </IconButton>
        </Tooltip>

        {/*
        <Tooltip title={"Remove"}>
          <IconButton
            onClick={() => {
              history.push("/classroom", {
                classroom: el
              });
            }}
          >
            <DeleteOutline className={classes.extras} />
          </IconButton>
        </Tooltip>
 */}
      </React.Fragment>
    );
  };

  dialogForm = () => {
    return (
      <React.Fragment>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Nombre"
          type="text"
          autoComplete="off"
          onChange={e => this.onElementTextChange(e, "lesson")}
          value={this.state.el.lesson}
          fullWidth
          variant="outlined"
        />
        <SelectTextfield
          margin="dense"
          label="Curso"
          suggestions={this.state.courses}
          value={this.state.el.courseID}
          variables={{ id: "id", label: "course" }}
          onChange={this.onChangeCourse}
        />

        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="URL de Imagen"
          type="text"
          autoComplete="off"
          onChange={e => this.onElementTextChange(e, "imageURL")}
          value={this.state.el.imageURL}
          fullWidth
          variant="outlined"
        />
      </React.Fragment>
    );
  };
}

export default withStyles(styles)(MyLessons);
