export default theme => ({
  title: {
    color: theme.palette.text.main
  },
  media: {
    height: 0,
    paddingTop: "100%"
  },
  card: {
    backgroundColor: theme.palette.background.darker,
    color: theme.palette.text.main,
    position: "relative",
    height: "100%"
  },
  cardAction: {
    position: "absolute",
    backgroundColor: "#000" + "000" + "00",
    bottom: 0,
    left: 0,
    right: 0,
    top: 0
  },
  overlay: {
    position: "absolute",
    color: "white",
    backgroundColor: "#000" + "000" + "88",
    bottom: 0,
    left: 0,
    right: 0,
    top: 0,
    display: "flex",
    flexDirection: "column",
    padding: 0,
    justifyContent: "center"
  },
  cardTitle: {
    fontSize: 22
  },
  cardActionsContainer: {
    position: "absolute",
    color: "white",
    left: 0,
    right: 0,
    bottom: 0,
    paddingTop: "60%",
    backgroundColor: "#000" + "000" + "00",
    "&:hover": {
      backgroundColor: "#000" + "000" + "55"
    }
  }
});
