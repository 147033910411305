import React, { Component } from "react";
import DynamicTable from "../../../common/dynamicTable/dynamicTable";
import TextField from "@material-ui/core/TextField";
import DialogsInfo from "../../../common/dialogsInfo";
import { getData as getCourses } from "../courses/courses-controller";
import {
  getData,
  addData,
  editData,
  getCourseLabel
} from "./lessons-controller";
import { imageURL, emptyURL } from "../../../common/imageExits";
import FilterDialog from "../../../common/filter/filterDialog";
import FilterText from "../../../common/filter/filters/filter-text";
import AutoFitImage from "react-image-autofit-frame";
import SelectTextfield from "../../../common/autocomplete/select/selectTextfield";

const dialogs = new DialogsInfo();

class Lessons extends Component {
  state = {
    imageURL: emptyURL,
    el: { id: "", course: "", imageURL: "" },
    rows: [],
    filterOpen: false,
    loading: false,
    courses: [],
    course: null
  };

  constructor(props) {
    super(props);
  }

  onChangeCourse = event => {
    let el = { ...this.state.el };
    el.courseID = event.target.value;
    el.course = getCourseLabel(el.courseID, this.state.courses);
    this.setState({ el });
  };

  onChangeType = event => {
    let el = { ...this.state.el };
    el.type = event.target.value;
    this.setState({ el });
  };

  onElementTextChange = (e, variable) => {
    let el = { ...this.state.el };
    el[variable] = e.target.value;
    if (variable === "imageURL") {
      imageURL(el.imageURL, src => {
        this.setState({ imageURL: src });
      });
    }

    this.setState({ el });
  };

  componentDidMount() {
    this.getCourses();
    this.getData();
  }

  getCourses = () => {
    getCourses(null, courses => {
      this.setState({ courses });
    });
  };

  getData = filters => {
    this.setState({ loading: true });
    getData(filters, rows => {
      this.setState({ rows, loading: false });
    });
  };

  startAdd = el => {
    let courseID = 0;
    let course = "";
    if (this.state.courses.length > 0) {
      courseID = this.state.courses[0].id;
      course = this.state.courses[0].course;
    }
    this.setState(
      {
        el: {
          id: "",
          lesson: "",
          imageURL: "",
          course,
          courseID,
          scene: "",
          type: "AR",
          requiredPoints: 0,
          completionPoints: 0
        }
      },
      () => {
        imageURL(this.state.el.imageURL, src => {
          this.setState({ imageURL: src });
        });
      }
    );
  };

  startEdit = el => {
    this.setState({ el }, () => {
      imageURL(this.state.el.imageURL, src => {
        this.setState({ imageURL: src });
      });
    });
  };

  onAdd = () => {
    this.setState({ loading: true });
    addData({ ...this.state.el }, el => {
      this.setState({ rows: [...this.state.rows, el], loading: false });
    });
  };

  onEdit = () => {
    this.setState({ loading: true });
    editData(this.state.el.id, this.state.el, el => {
      let rows = [...this.state.rows];
      rows.forEach(i => {
        if (i.id === el.id) {
          i.course = el.course;
          i.courseID = el.courseID;
          i.lesson = el.lesson;
          i.imageURL = el.imageURL;
          i.type = el.type;
          i.scene = el.scene;
          i.requiredPoints = el.requiredPoints;
          i.completionPoints = el.completionPoints;
        }
      });
      this.setState({ rows, loading: false });
    });
  };

  render() {
    return (
      <React.Fragment>
        <DynamicTable
          title="Lesson"
          header={["Lesson", "Course"]}
          loading={this.state.loading}
          rows={this.state.rows}
          model={["lesson", "course"]}
          startAdd={this.startAdd}
          startEdit={this.startEdit}
          startDelete={this.startDelete}
          addDialog={{
            dialog: dialogs.addDialog("lesson"),
            dialogForm: this.dialogForm,
            action: this.onAdd
          }}
          editDialog={{
            dialog: dialogs.editDialog("lesson"),
            dialogForm: this.dialogForm,
            action: this.onEdit
          }}
          deleteDialog={{
            dialog: dialogs.deleteDialog("lesson"),
            dialogForm: this.deleteDialogForm,
            action: this.onDelete
          }}
          filterOpen={this.filterOpen}
        />

        <FilterDialog
          refs={this.state.refs}
          handleClose={this.filterClose}
          open={this.state.filterOpen}
          filter={this.filter}
        >
          <FilterText label="Lesson" ref={this.course} id={"lesson"} />
        </FilterDialog>
      </React.Fragment>
    );
  }

  dialogForm = () => {
    return (
      <React.Fragment>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Lesson name"
          type="text"
          autoComplete="off"
          onChange={e => this.onElementTextChange(e, "lesson")}
          value={this.state.el.lesson}
          fullWidth
          variant="outlined"
        />
        <SelectTextfield
          margin="dense"
          label="Course"
          suggestions={this.state.courses}
          value={this.state.el.courseID}
          variables={{ id: "id", label: "course" }}
          onChange={this.onChangeCourse}
        />

        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Image URL"
          type="text"
          autoComplete="off"
          onChange={e => this.onElementTextChange(e, "imageURL")}
          value={this.state.el.imageURL}
          fullWidth
          variant="outlined"
        />
        <div style={{ height: 20 }} />
        <AutoFitImage
          frameWidth="500px"
          frameHeight="400px"
          imgSrc={this.state.imageURL}
        />
      </React.Fragment>
    );
  };
}

export default Lessons;
