import * as React from "react";
import * as ReactMarkdown from "react-markdown"; //https://github.com/rexxars/react-markdown
import { Typography } from "@material-ui/core";
import { useStyles } from "./docs-styles";
import RemarkMathPlugin from "remark-math";
import CodeBlock from "./CodeBlock";
import "katex/dist/katex.min.css";
import TeX from "@matejmazur/react-katex";

function Title(props) {
  const classes = useStyles();
  return (
    <Typography
      {...props}
      variant={`h${props.level}`}
      classes={{
        root: classes.title,
        h1: classes.h1,
        h2: classes.h2,
        h3: classes.h3,
        h4: classes.h4,
        h5: classes.h5,
        h6: classes.h6
      }}
    />
  );
}

function Image(props) {
  return <img {...props} style={{ maxWidth: "90%" }} />;
}

function Content(props) {
  const classes = useStyles();
  return <p {...props} className={classes.content}></p>;
}

function ContentBold(props) {
  const classes = useStyles();
  return <strong {...props}></strong>;
}

export const MDRenderer = props => {
  const classes = useStyles();

  const newProps = {
    ...props,
    plugins: [RemarkMathPlugin],
    renderers: {
      ...props.renderers,
      paragraph: props => <Content {...props}></Content>,
      strong: props => <ContentBold {...props}></ContentBold>,
      heading: props => <Title {...props}></Title>,
      math: props => <TeX>{props.value}</TeX>,
      inlineMath: props => <TeX>{props.value}</TeX>,
      code: CodeBlock,
      image: Image
    }
  };

  return <ReactMarkdown {...newProps}></ReactMarkdown>;
};
