import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import clsx from "clsx";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Collapse from "@material-ui/core/Collapse";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { WebAssetOutlined } from "@material-ui/icons";
import Skeleton from "@material-ui/lab/Skeleton";

const styles = theme => ({
  card: {
    //backgroundColor: theme.palette.text.secondary,
    color: theme.palette.text.main,
    borderRadius: 0
  },
  media: {
    height: 0,
    paddingTop: "75%" // 16:9
  },
  subtitle: {
    color: theme.palette.text.secondary
  },
  cardTitle: {
    color: theme.palette.text.main
  },
  cardSubtitle: {
    color: theme.palette.text.secondary
  },
  actions: {
    color: theme.palette.text.secondary,
    display: "flex",
    flexDirection: "row",
    width: "100%"
  }
});

class ComplexCard extends Component {
  state = { expaned: false };

  setExpanded = () => {
    this.setState({ expnded: !this.state.expaned });
  };

  render() {
    const {
      classes,
      id = "id",
      title = "Title",
      subtitle = "Subtitle",
      actionArea = () => {},
      imageURL,
      element = null,
      loading = true
    } = this.props;
    return (
      <Card className={classes.card} elevation={0} key={id}>
        {loading ? (
          <Skeleton className={classes.media} />
        ) : (
          <CardMedia className={classes.media} image={imageURL} title={title} />
        )}

        {loading ? (
          <Skeleton />
        ) : (
          <Typography
            variant={"h6"}
            align={"left"}
            className={classes.cardTitle}
          >
            {title}
          </Typography>
        )}

        {loading ? (
          <Skeleton />
        ) : (
          <Typography
            variant={"body1"}
            align={"left"}
            className={classes.cardSubtitle}
          >
            {subtitle}
          </Typography>
        )}
        {loading ? (
          <Skeleton width="60%" />
        ) : (
          <div className={classes.actions}>{actionArea(element)}</div>
        )}

        <br />
      </Card>
    );
  }
}

ComplexCard.propTypes = {
  classes: PropTypes.object.isRequired,
  id: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  imageURL: PropTypes.string,
  actionArea: PropTypes.func,
  loading: PropTypes.bool,
  element: PropTypes.object
};

export default withStyles(styles)(ComplexCard);
