import React, { Component } from "react";
import "./App.css";
import { darkTheme } from "./themes/dark-theme";
import { lightTheme } from "./themes/light-theme";
import { MuiThemeProvider } from "@material-ui/core/styles";
import Routes from "./routes";
import AuthMngr from "./components/login/authmngr";
import PageContainer from "./components/common/pageContainer";

require("typeface-rubik");
require("typeface-open-sans");
require("typeface-fira-sans");
require("typeface-montserrat");
require("typeface-josefin-sans");

class App extends Component {
  render() {
    const authMngr = AuthMngr.getInstance();
    return (
      <div className="App">
        <MuiThemeProvider theme={lightTheme}>
          <PageContainer role={"public"}>
            <Routes />
          </PageContainer>
        </MuiThemeProvider>
      </div>
    );
  }
}

export default App;
