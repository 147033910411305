import React, { Component } from "react";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import styles from "./container-styles";
import history from "../../common/history";
import { isMobile, isAndroid } from "mobile-device-detect";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import IconButton from "@material-ui/core/IconButton";
import { Divider } from "@material-ui/core";

class MainCointainer extends Component {
  state = { mobileMoreAnchorEl: null };

  redirectToSignIn = () => {
    history.push("/signin");
  };

  handleMobileMenuOpen = event => {
    this.setState({ mobileMoreAnchorEl: event.currentTarget });
  };

  handleMobileMenuClose = () => {
    this.setState({ mobileMoreAnchorEl: null });
  };

  renderToolBarMenu = classes => {
    const { mobileMoreAnchorEl } = this.state;
    const mobileOpen = Boolean(mobileMoreAnchorEl);
    if (isMobile) {
      return (
        <div>
          <Button
            color="inherit"
            variant="outlined"
            onClick={this.redirectToSignIn}
          >
            Iniciar Sesión
          </Button>
          <IconButton
            aria-label="More"
            aria-haspopup="true"
            className={classes.moreButton}
            onClick={this.handleMobileMenuOpen}
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            id="long-menu"
            anchorEl={mobileMoreAnchorEl}
            open={mobileOpen}
            onClose={this.handleMobileMenuClose}
            PaperProps={{
              style: {
                width: 200
              }
            }}
          >
            <MenuItem
              key={0}
              onClick={() => {
                this.handleMobileMenuClose();
                history.push("/about");
              }}
            >
              Acerca
            </MenuItem>

            <MenuItem
              key={1}
              onClick={() => {
                this.handleMobileMenuClose();
                history.push("/contact");
              }}
            >
              Contacto
            </MenuItem>
            <MenuItem
              key={1}
              onClick={() => {
                this.handleMobileMenuClose();
                history.push("/downloads");
              }}
            >
              Descargas
            </MenuItem>
            <MenuItem
              key={2}
              onClick={() => {
                this.handleMobileMenuClose();
                this.redirectToSignIn();
              }}
            >
              Iniciar Sesión
            </MenuItem>
          </Menu>
        </div>
      );
    } else {
      return (
        <React.Fragment>
          <Button
            color="inherit"
            onClick={() => {
              history.push("/about");
            }}
          >
            Acerca
          </Button>
          <Button
            color="inherit"
            onClick={() => {
              history.push("/contact");
            }}
          >
            ContactO
          </Button>
          <Button
            color="inherit"
            onClick={() => {
              history.push("/downloads");
            }}
          >
            Descargas
          </Button>
          <Button
            color="inherit"
            variant="outlined"
            onClick={this.redirectToSignIn}
          >
            Iniciar Sesión
          </Button>
        </React.Fragment>
      );
    }
  };

  render() {
    const classes = this.props.classes;

    return (
      <div className={classes.root}>
        <AppBar position="static" className={classes.appBar}>
          <Toolbar>
            <Typography
              variant="h5"
              color="inherit"
              noWrap
              onClick={() => {
                history.push("/");
              }}
            >
              Babel
            </Typography>

            <div style={{ flexGrow: 1 }} />
            {this.renderToolBarMenu(classes)}
          </Toolbar>
          <Divider />
        </AppBar>

        <main className={classes.content}>
          <div className={classes.toolbar} />
          {this.props.children}
        </main>
      </div>
    );
  }
}

MainCointainer.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(MainCointainer);
