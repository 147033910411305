export default theme => ({
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    height: "100vh",
    overflow: "auto"
  },
  shareLinkContainer: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: 400
  },
  shareLinkText: {
    flex: 1
  },
  iconButton: {
    padding: 10
  },
  divider: {
    height: 28,
    margin: 4
  }
});
