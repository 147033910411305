import React, { Component } from "react";
import styles from "./myClassroom-styles";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

import {
  joinClassroom,
  getData
} from "../../../../backend-communication/classroom-controller";
import PublicMain from "../../main/publicMain";
import SignedRoute from "../../../common/signedRoute";
import CardManagement from "../../../common/dynamicCardManagement/cardManagement";
import TextField from "@material-ui/core/TextField";
import DialogsInfo from "../../../common/dialogsInfo";

import history from "../../../common/history";
import { Button, Dialog, DialogTitle, DialogContent } from "@material-ui/core";
import { cancelRequests } from "../../../../backend-communication/cancel-axios-token";
const dialogs = new DialogsInfo();

class MyClassroom extends Component {
  state = {
    rows: [],
    pageLoading: true,
    shareCode: "",
    loading: false,
    dialogOpen: false,
    joinLoading: false
  };
  getData = filters => {
    this.setState({ loading: true });
    getData(filters, (err, rows) => {
      this.setState({ rows, loading: false, pageLoading: false });
    });
  };

  onElementTextChange = (e, variable) => {
    let el = { ...this.state.el };
    el[variable] = e.target.value;
    this.setState({ shareCode: e.target.value });
  };

  startAdd = () => {
    this.setState({ shareCode: "", dialogOpen: true });
  };

  handleDialogChange = val => {
    this.setState({ dialogOpen: val });
  };

  onAdd = () => {
    this.setState({ dialogOpen: false, joinLoading: true });
    joinClassroom(this.state.shareCode, (err, classroomId) => {
      if (!err)
        history.push({ pathname: "classroom", search: `?id=${classroomId}` });
      else this.setState({ joinLoading: false });
    });
  };

  componentWillUnmount() {
    cancelRequests("Unmounting");
  }

  render() {
    const { classes } = this.props;
    const { rows } = this.state;
    return (
      <React.Fragment>
        <SignedRoute role="public" executeOnValid={this.getData}>
          <main className={classes.content}>
            <Button
              onClick={this.startAdd}
              color="secondary"
              variant="outlined"
              disabled={this.state.pageLoading || this.state.joinLoading}
              style={{
                marginBottom: 0,
                flexDirection: "column",
                display: "flex"
              }}
            >
              Unirme
            </Button>
            <CardManagement
              titleFieldName="name"
              thumbnailFieldName="imageURL"
              rows={rows}
              loading={this.state.pageLoading}
              onClickCard={el =>
                history.push({
                  pathname: "/classroom",
                  search: `?id=${el.id}`
                })
              }
            />
          </main>
          {this.dialogForm()}
        </SignedRoute>
      </React.Fragment>
    );
  }

  dialogForm = () => {
    return (
      <React.Fragment>
        <Dialog
          open={this.state.dialogOpen}
          onClose={() => this.handleDialogChange(false)}
        >
          <DialogTitle>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Código"
              type="text"
              autoComplete="off"
              onChange={e => this.onElementTextChange(e)}
              value={this.state.shareCode}
              fullWidth
              variant="outlined"
            />
          </DialogTitle>

          <DialogContent>
            <Button variant="outlined" onClick={this.onAdd} fullWidth>
              Unirme
            </Button>
          </DialogContent>
        </Dialog>
      </React.Fragment>
    );
  };
}

MyClassroom.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(MyClassroom);
