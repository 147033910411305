export default theme => ({
  root: {
    flexGrow: 1,
    marginBottom: 10
  },
  container: {
    flexGrow: 1,
    position: "relative"
  },
  paper: {
    position: "absolute",
    zIndex: 1,
    marginTop: theme.spacing(1),
    marginLeft: 0,
    marginRight: 0
  },
  chip: {
    margin: `${theme.spacing(0.5)}px ${theme.spacing(0.25)}px`
  },
  inputRoot: {
    flexWrap: "wrap"
  },
  inputInput: {
    width: "auto",
    flexGrow: 1,
    color: theme.palette.primary.font
  },
  divider: {
    height: theme.spacing(2)
  },
  textField: {
    borderColor: theme.palette.secondary.font
  },
  cssLabel: {
    "&$cssFocused": {
      color: theme.palette.primary.font
    },
    color: theme.palette.secondary.font
  },
  cssFocused: {},
  cssOutlinedInput: {
    "&$cssFocused $notchedOutline": {
      borderColor: `${theme.palette.primary.font} !important`
    }
  },
  notchedOutline: {
    borderColor: `${theme.palette.secondary.font} !important`
  },
  input: {
    color: theme.palette.primary.font
  },
  list: {
    backgroundColor: theme.palette.background.paper,
    position: "relative",
    overflow: "auto"
  }
});
