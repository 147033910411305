export default theme => ({
  root: {
    display: "flex",
    height: "100vh"
  },
  appBar: {
    position: "absolute",
    backgroundColor: theme.palette.background.paper,
    boxShadow: "none",
    color: theme.palette.text.primary
  },
  icon: {
    marginRight: theme.spacing(2)
  },
  textTitle: {
    color: theme.palette.text.primary,
    fontWeight: 500
  },
  textDescription: {
    color: theme.palette.text.primary,
    fontWeight: 400
  },
  moreButton: {
    color: theme.palette.text.primary
  },
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper
  }
});
