import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import DownloadIcon from "@material-ui/icons/CloudDownloadTwoTone";
import HelpIcon from "@material-ui/icons/HelpTwoTone";
import ListSubheader from "@material-ui/core/ListSubheader";
import history from "../../common/history";
import {
  Drawer,
  Tooltip,
  MenuItem,
  IconButton,
  Typography,
  Toolbar
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
import logo from "../../../img/logo-svg.svg";

import {
  HomeTwoTone,
  ExploreTwoTone,
  GroupTwoTone,
  PostAddTwoTone,
  AccountCircleTwoTone,
  EmailTwoTone,
  GetAppTwoTone,
  HelpTwoTone,
  SendTwoTone
} from "@material-ui/icons";

const styles = theme => ({
  list: {
    width: 250
  },
  fullList: {
    width: "auto"
  },
  drawer: {
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    flexShrink: 0
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: 240
  },
  swipableDrawer: {
    width: 240,
    flexShrink: 0
  },
  imageIcon: {
    width: 25,
    height: 25
  },
  menuItem: {
    backgroundColor: theme.palette.background.paper,
    "&$selected": {
      backgroundColor: theme.palette.background.paper
    }
  },
  selected: {}
});

const menu = [
  {
    label: "Inicio",
    icon: HomeTwoTone,
    action: () => {
      history.push("/home");
    },
    pathName: "/home"
  } /*
  {
    label: "Explore",
    icon: ExploreTwoTone,
    action: () => {
      history.push("/explore");
    },
    pathName: "/explore"
  },*/,
  {
    label: "Salones",
    icon: GroupTwoTone,
    action: () => {
      history.push("/classrooms");
    },
    pathName: "/classrooms"
  },
  {
    label: "Administración",
    icon: PostAddTwoTone,
    action: () => {
      history.push("/management");
    },
    pathName: "/management"
  }
];

const list3 = [
  {
    label: "Descargas",
    icon: DownloadIcon,
    action: () => {
      history.push("/downloads");
    },
    pathName: "/downloads"
  },
  {
    label: "Contacto",
    icon: SendTwoTone,
    action: () => {
      history.push("/contact");
    },
    pathName: "/contact"
  },
  {
    label: "Ayuda",
    icon: HelpIcon,
    action: () => history.push("/docs"),
    pathName: "/docs"
  }
];

function createSection(list, subheader, pathname, classes) {
  return (
    <List
      subheader={
        subheader != null && (
          <ListSubheader component="div">{subheader}</ListSubheader>
        )
      }
    >
      {list.map((item, index) => (
        <MenuItem
          button
          key={item.label}
          onClick={item.action}
          selected={item.pathName == pathname}
          classes={{ root: classes.menuItem, selected: classes.selected }}
        >
          <Tooltip title={item.label}>
            <ListItemIcon>
              <item.icon
                color={item.pathName == pathname ? "primary" : undefined}
              />
            </ListItemIcon>
          </Tooltip>
          <ListItemText primary={item.label} />
        </MenuItem>
      ))}
    </List>
  );
}

class PublicDrawer extends React.Component {
  state = { location: "" };

  updateSelected(selectedIndex) {
    this.setState({ selected: selectedIndex });
  }

  historyUnlisten = null;

  componentDidMount() {
    this.historyUnlisten = history.listen((location, action) => {
      this.setState({ location: location.pathname });
    });

    this.setState({ location: window.location.pathname });
  }

  componentWillMount() {
    if (this.historyUnlisten) this.historyUnlisten();
  }

  render() {
    const { classes } = this.props;

    const sideList = (
      <div className={classes.list}>
        {createSection(menu, null, this.state.location, classes)}
        <Divider />
        {createSection(list3, null, this.state.location, classes)}
      </div>
    );

    return (
      <div>
        <Drawer
          variant="permanent"
          className={classes.drawer}
          classes={{ paper: classes.drawer }}
        >
          <div className={classes.toolbar} />
          <div
            tabIndex={0}
            role="button"
            onKeyDown={this.props.onClose}
            onClick={this.props.onClose}
          >
            {sideList}
          </div>
        </Drawer>

        <SwipeableDrawer
          open={this.props.open}
          onClose={this.props.onClose}
          onOpen={this.props.onOpen}
        >
          <Toolbar style={{ padding: 0 }}>
            <IconButton
              aria-label="Go home"
              onClick={() => history.push("/home")}
            >
              <img src={logo} className={classes.imageIcon} alt="logo" />
            </IconButton>
            <Typography
              style={{
                marginTop: 8,
                lineHeight: 0.75
              }}
              variant="h5"
              color={"textPrimary"}
              onClick={() => history.push("/home")}
            >
              Babel
            </Typography>
          </Toolbar>
          <Divider />
          <div
            tabIndex={0}
            role="button"
            onKeyDown={this.props.onClose}
            onClick={this.props.onClose}
            className={classes.swipableDrawer}
          >
            {sideList}
          </div>
        </SwipeableDrawer>
      </div>
    );
  }
}

PublicDrawer.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(PublicDrawer);
