import React, { Component } from "react";
import styles from "./cardManagement-styles";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

import {
  Typography,
  GridList,
  GridListTile,
  Card,
  CardMedia,
  CardActionArea
} from "@material-ui/core";
import withWidth, { isWidthUp } from "@material-ui/core/withWidth";
import Skeleton from "@material-ui/lab/Skeleton";

class CardManagement extends Component {
  componentDidMount() {}

  getGridListCols = () => {
    const { xl = 6, lg = 5, md = 2, sm = 1 } = this.props;
    if (isWidthUp("xl", this.props.width)) {
      return xl;
    }

    if (isWidthUp("lg", this.props.width)) {
      return lg;
    }

    if (isWidthUp("md", this.props.width)) {
      return md;
    }

    return sm;
  };

  render() {
    const { classes, loading, rows } = this.props;

    const data = loading ? new Array(3).fill("") : rows;

    return (
      <React.Fragment>
        <br />
        <GridList cols={this.getGridListCols()} spacing={6}>
          {data.map((el, index) => (
            <GridListTile key={index}>
              <Card className={classes.card} key={index}>
                {loading ? (
                  <Skeleton className={classes.media} />
                ) : (
                  this.renderCardAction(el)
                )}
              </Card>
            </GridListTile>
          ))}
        </GridList>
      </React.Fragment>
    );
  }

  renderCardAction = el => {
    const { classes } = this.props;
    if (this.props.onClickCard) {
      return (
        <CardActionArea
          className={classes.cardAction}
          onClick={() => {
            this.props.onClickCard(el);
          }}
        >
          {this.renderCardContent(el)}
        </CardActionArea>
      );
    } else {
      return this.renderCardContent(el);
    }
  };

  renderCardContent = el => {
    const { classes, thumbnailFieldName, titleFieldName, blur } = this.props;

    const thumbnailStyle = { filter: `blur(${blur}px)` };

    return (
      <React.Fragment>
        <CardMedia
          className={classes.media}
          image={
            el[thumbnailFieldName] == null
              ? "https://www.macmillandictionary.com/external/slideshow/full/Grey_full.png"
              : el[thumbnailFieldName]
          }
          title={el[titleFieldName]}
          style={thumbnailStyle}
        />
        <div className={classes.overlay}>
          <Typography
            variant="body1"
            className={classes.cardTitle}
            align="center"
          >
            {el[titleFieldName]}
          </Typography>
        </div>
        <div className={classes.cardActionsContainer}>
          {this.props.cardActions && this.props.cardActions(el)}
        </div>
      </React.Fragment>
    );
  };
}

CardManagement.propTypes = {
  classes: PropTypes.object.isRequired,
  /**
   * Data to display
   */
  rows: PropTypes.array,

  /**
   * Field name of the thitle
   */
  titleFieldName: PropTypes.string,
  /**
   * Field name of the thumbnail
   */
  thumbnailFieldName: PropTypes.string,

  /**
   * Action area
   */
  onClickCard: PropTypes.func,

  /**
   * Function that return some actions
   */
  cardActions: PropTypes.func,
  /**
   *Blur background
   */
  blur: PropTypes.number,
  loading: PropTypes.bool,
  xl: PropTypes.number,
  lg: PropTypes.number,
  md: PropTypes.number,
  sm: PropTypes.number
};

export default withWidth()(withStyles(styles)(CardManagement));
