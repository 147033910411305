import React, { Component } from "react";
import styles from "./publicMain-styles";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import MenuIcon from "@material-ui/icons/Menu";
import AccountCircle from "@material-ui/icons/AccountCircle";
import MoreIcon from "@material-ui/icons/MoreVert";
import { withStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import history from "../../common/history";
import AuthMngr from "../../login/authmngr";
import Drawer from "./drawer";
import logo from "../../../img/logo-svg.svg";
import { Divider, Icon } from "@material-ui/core";

class PublicMain extends Component {
  state = {
    anchorEl: null,
    mobileMoreAnchorEl: null,
    drawerOpen: false,
    appbar: "appBar",
    photoURL: null
  };

  e = () => {
    AuthMngr.getInstance().getFirebaseUserInfo(info => {
      this.setState({ photoURL: info.photoURL });
    });
  };

  componentDidMount() {
    this.e = AuthMngr.getInstance().subscribeOrExecute(this.e);
    window.addEventListener("scroll", this.scrollEvent);
    if (this.props.blockToolbar) this.setState({ appbar: "appBarColor" });
  }

  componentWillUnmount() {
    AuthMngr.getInstance().unsubscribeEvent(this.e);
  }

  signOut = () => {
    AuthMngr.getInstance().signOut(() => {
      history.push("/");
    });
  };

  handleProfileMenuOpen = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleMenuClose = () => {
    this.setState({ anchorEl: null });
    this.handleMobileMenuClose();
  };

  handleMobileMenuOpen = event => {
    this.setState({ mobileMoreAnchorEl: event.currentTarget });
  };

  handleMobileMenuClose = () => {
    this.setState({ mobileMoreAnchorEl: null });
  };

  drawerOpen = () => {
    this.setState({ drawerOpen: true });
  };

  drawerClose = () => {
    this.setState({ drawerOpen: false });
  };

  scrollEvent = e => {
    if (this.props.blockToolbar) {
      this.setState({ appbar: "appBarColor" });
    } else {
      if (window.scrollY > 450) {
        this.setState({ appbar: "appBarColor" });
      } else {
        this.setState({ appbar: "appBar" });
      }
    }
  };

  renderAvatar(classes) {
    if (this.state.photoURL == null) {
      return <AccountCircle />;
    } else {
      return (
        <Avatar
          alt="Account"
          src={this.state.photoURL}
          className={classes.avatar}
        />
      );
    }
  }
  render() {
    const { anchorEl, mobileMoreAnchorEl } = this.state;
    const { classes } = this.props;
    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    const renderMenu = (
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        open={isMenuOpen}
        onClose={this.handleMenuClose}
      >
        {/* 
       <MenuItem onClick={this.handleMenuClose}>Profile</MenuItem>
      */}
        <MenuItem onClick={this.signOut}>Cerrar sesión</MenuItem>
      </Menu>
    );

    const renderMobileMenu = (
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        open={isMenuOpen}
        onClose={this.handleMenuClose}
      >
        {/* 
       <MenuItem onClick={this.handleMenuClose}>Profile</MenuItem>
      */}
        <MenuItem onClick={this.signOut}>Cerrar sesión</MenuItem>
      </Menu>
    );

    return (
      <div className={classes.root}>
        <AppBar
          position="fixed"
          className={classes[this.state.appbar]}
          elevation={0}
        >
          <Toolbar className={classes[this.state.appbar]}>
            <IconButton
              className={classes.menuButton}
              aria-label="Open drawer"
              onClick={this.drawerOpen}
            >
              <MenuIcon />
            </IconButton>

            <IconButton
              aria-label="Go home"
              onClick={() => history.push("/home")}
            >
              <img src={logo} className={classes.imageIcon} alt="logo" />
            </IconButton>
            <Typography
              className={classes.title}
              variant="h5"
              color={"textPrimary"}
              onClick={() => history.push("/home")}
            >
              Babel
            </Typography>
            <div className={classes.grow} />
            <IconButton
              aria-owns={isMenuOpen ? "material-appbar" : undefined}
              aria-haspopup="true"
              onClick={this.handleProfileMenuOpen}
              color="inherit"
            >
              {this.renderAvatar(classes)}
            </IconButton>
          </Toolbar>
          <Divider />
        </AppBar>
        <Drawer
          open={this.state.drawerOpen}
          onClose={this.drawerClose}
          onOpen={this.drawerOpen}
        />
        {renderMenu}
        {/*renderMobileMenu*/}

        <main className={classes.content}>
          <div className={classes.toolbar} />
          {this.props.children}
        </main>
      </div>
    );
  }
}

PublicMain.propTypes = {
  classes: PropTypes.object.isRequired,
  blockToolbar: PropTypes.bool
};

export default withStyles(styles)(PublicMain);
