import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import Badge from "@material-ui/core/Badge";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import NotificationsIcon from "@material-ui/icons/Notifications";
import { mainListItems, secondaryListItems } from "./list";
import Avatar from "@material-ui/core/Avatar";
import firebase from "firebase";
import AccountCircle from "@material-ui/icons/AccountCircleRounded";
import MenuItem from "@material-ui/core/MenuItem";
import SingOut from "@material-ui/icons/ExitToApp";
import Menu from "@material-ui/core/Menu";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import styles from "./dashboard-styles";

import AuthMngr from "../../login/authmngr";

class Dashboard extends React.Component {
  state = {
    anchorEl: null,
    open: false,
    url: "",
    photoURL: null
  };

  componentDidMount() {
    //this.getSession();
  }
  getSession = () => {
    AuthMngr.getInstance().getSession(res => {
      this.setState({ photoURL: res.photoURL });
      //alert(JSON.stringify(res));
    });
  };

  handleDrawerOpen = () => {
    this.setState({ open: true });
  };

  handleDrawerClose = () => {
    this.setState({ open: false });
  };

  handleMenu = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleLogOut = () => {
    this.handleMenuClose();
    if (this.props.logout) this.props.logout();
  };
  handleMenuClose = () => {
    this.setState({ anchorEl: null });
  };

  renderAvatar(classes) {
    if (this.state.photoURL == null) {
      return <AccountCircle />;
    } else {
      return (
        <Avatar
          alt="Account"
          src={this.state.photoURL}
          className={classes.avatar}
        />
      );
    }
  }

  render() {
    const { classes } = this.props;
    const { anchorEl } = this.state;
    const openMenu = Boolean(anchorEl);

    return (
      <div className={classes.root}>
        <CssBaseline />
        <AppBar
          position="absolute"
          className={classNames(
            classes.appBar,
            this.state.open && classes.appBarShift
          )}
        >
          <Toolbar
            disableGutters={!this.state.open}
            className={classes.toolbar}
          >
            <IconButton
              color="inherit"
              aria-label="Open drawer"
              onClick={this.handleDrawerOpen}
              className={classNames(
                classes.menuButton,
                this.state.open && classes.menuButtonHidden
              )}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              className={classes.title}
            >
              Dashboard
            </Typography>
            <IconButton color="inherit" onClick={this.handleMenu}>
              {this.renderAvatar(classes)}
            </IconButton>

            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              transformOrigin={{ vertical: "top", horizontal: "right" }}
              open={openMenu}
              onClose={this.handleMenuClose}
            >
              <MenuItem onClick={this.handleLogOut}>
                <ListItemIcon className={classes.icon}>
                  <SingOut />
                </ListItemIcon>
                <ListItemText
                  classes={{ primary: classes.primary }}
                  inset
                  primary="Sign out"
                />
              </MenuItem>
            </Menu>
          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          className={classes.drawer}
          classes={{
            paper: classes.drawerPaper
          }}
        >
          <div className={classes.toolbarIcon}>
            <IconButton onClick={this.handleDrawerClose}>
              <ChevronLeftIcon />
            </IconButton>
          </div>
          <Divider />
          <List>{mainListItems}</List>
          <Divider />
        </Drawer>
        <main className={classes.content}>{this.props.children}</main>
      </div>
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Dashboard);
