import React, { Component } from "react";
import SignedRoute from "../common/signedRoute";

class Logging extends Component {
  render() {
    return <SignedRoute authenticate={false} />;
  }
}

export default Logging;
