export default theme => ({
  table: {},
  tableRow: {
    cursor: "pointer",
    backgroundColor: theme.palette.background.light,
    "&:hover": {
      backgroundColor: theme.palette.background.lighter
    }
  },
  tableRowHover: {
    "&:hover": {
      backgroundColor: theme.palette.background.dark
    }
  },
  tableCell: {
    fontSize: 14,
    flex: 1,
    color: theme.palette.text.main,
    border: "0px solid red"
  },
  head: {
    backgroundColor: theme.palette.background.darker
  },
  noClick: {
    cursor: "initial"
  },
  buttonEdit: {
    margin: 0,
    color: theme.palette.primary.warning,
    fontSize: "small"
  },
  buttonDelete: {
    margin: 0,
    color: theme.palette.primary.danger
  },
  buttonAdd: {
    margin: theme.spacing(1),
    color: theme.palette.text.main
  },
  tableWrapper: {
    overflowX: "auto"
  },
  tablePagination: {
    backgroundColor: theme.palette.background.main,
    color: theme.palette.text.main
  },
  linearProgress: {
    colorPrimary: theme.palette.secondary.main,
    barColorPrimary: theme.palette.secondary.light
  },
  linearColorPrimary: {
    backgroundColor: theme.palette.secondary.light
  },
  linearBarColorPrimary: {
    backgroundColor: theme.palette.secondary.dark
  }
});
