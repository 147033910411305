import React, { Component } from "react";
import { Router, Route, Switch } from "react-router-dom";
import history from "./components/common/history";

//Main
import Home from "./components/about/home/home";
import Downloads from "./components/about/downloads/downloads";
import About from "./components/about/about/about";
import Contact from "./components/about/contact/contact";
import Login from "./components/login/login";
import LoginLoading from "./components/login/loginLoading";
import Docs from "./components/docs/docs";

//PUBLIC
import HomePublic from "./components/public/homePublic";
import Management from "./components/public/management/management";
import LessonHistory from "./components/public/history/lessonHistory";
import CreateClassroom from "./components/public/classrooms/create/createClassroom";
import MyClassrooms from "./components/public/classrooms/myClassrooms/myClassroom";
import MyLessons from "./components/public/myLessons/mylessons";
import Classroom from "./components/public/classrooms/classroom/classroom";
import LessonCreator from "./components/public/lessonCreator/lessonCreator";

//ADMIN
import HomeAdmin from "./components/admin/homeAdmin";
import ManageLessons from "./components/admin/manageLessons";
import ManageCourses from "./components/admin/manageCourses";
import ManageSubjects from "./components/admin/manageSubjects";
import Error404 from "./components/common/Error404";
import Classrooms from "./components/admin/manageClassrooms";
import HomeP from "./components/public/home/home";
import JoinClassroomLink from "./components/public/classrooms/JoinClassroomLink";
import ARPolicies from "./components/about/policies/arpolicies";
import SignUp from "./components/login/signUp";
import Verify from "./components/login/VerifyEmail";

export default () => (
  <Router history={history}>
    <Switch>
      <Route path="/" exact render={props => <Home {...props} />} />
      <Route
        path="/downloads"
        exact
        render={props => <Downloads {...props} />}
      />
      <Route path="/about" exact render={props => <About {...props} />} />
      <Route path="/contact" exact render={props => <Contact {...props} />} />
      <Route path="/login" exact render={props => <Login {...props} />} />
      <Route path="/signin" exact render={props => <Login {...props} />} />
      <Route path="/signup" exact render={props => <SignUp {...props} />} />
      <Route path="/verify" exact render={props => <Verify {...props} />} />
      <Route path="/docs" exact render={props => <Docs {...props} />} />
      <Route
        path="/logingloading"
        exact
        render={props => <LoginLoading {...props} />}
      />
      <Route
        path="/admin/home"
        exact
        render={props => <ManageSubjects {...props} />}
      />
      <Route
        path="/admin/classrooms"
        exact
        render={props => <Classrooms {...props} />}
      />
      <Route
        path="/admin/lessons"
        exact
        render={props => <ManageLessons {...props} />}
      />
      <Route
        path="/admin/courses"
        exact
        render={props => <ManageCourses {...props} />}
      />
      <Route
        path="/admin/subjects"
        exact
        render={props => <ManageSubjects {...props} />}
      />
      <Route path="/home" exact render={props => <HomeP {...props} />} />
      <Route
        path="/explore"
        exact
        render={props => <HomePublic {...props} />}
      />
      <Route
        path="/lessonHistory"
        exact
        render={props => <LessonHistory {...props} />}
      />
      <Route
        path="/manageClassrooms"
        exact
        render={props => <CreateClassroom {...props} />}
      />
      <Route
        path="/myLessons"
        exact
        render={props => <MyLessons {...props} />}
      />
      <Route
        path="/classrooms"
        exact
        render={props => <MyClassrooms {...props} />}
      />
      <Route
        path="/management"
        exact
        render={props => <Management {...props} />}
      />
      <Route
        path="/classroom"
        exact
        render={props => <Classroom {...props} />}
      />
      <Route
        path="/joinClassroom"
        exact
        render={props => <JoinClassroomLink {...props} />}
      />

      <Route
        path="/ar-policies"
        exact
        render={props => <ARPolicies {...props} />}
      />
      <Route path="*" render={props => <Error404 {...props} />} />
    </Switch>
  </Router>
);
