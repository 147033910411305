import React, { Component } from "react";
import Drawer from "./drawer/docs-drawer";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import styles from "./docs-styles";
import { getMarkdown } from "./docs-controller";
import { MDRenderer } from "./md-renderer";
import history from "../common/history";
import { isMobile, isAndroid } from "mobile-device-detect";
import { Breadcrumbs, Link } from "@material-ui/core";
const queryString = require("query-string");

class Docs extends Component {
  state = { currentDoc: "introduction" };

  componentDidMount() {
    const query = queryString.parse(this.props.location.search);

    if (query.id == null) {
      this.changeDoc("primeros_pasos");
    } else {
      getMarkdown(query.id, res => {
        this.setState({ currentDoc: res });
      });
    }
  }

  changeDoc = val => {
    history.push({ pathname: "/docs", search: `?id=${val}` });

    getMarkdown(val, res => {
      this.setState({ currentDoc: res });
    });
  };

  setOpen = (name, val) => {
    this.setState({ [name]: val });
  };

  list = () => {
    return [
      {
        text: "Ver Documentación",
        action: () => {
          this.setOpen("openDocumentacion", !this.state.openDocumentacion);
        },
        children: {
          open: this.state.openDocumentacion,
          elements: [
            {
              text: "Empieza con Babel",
              action: () => {
                this.setOpen(
                  "openPrimerosPasos",
                  !this.state.openPrimerosPasos
                );
              },
              children: {
                open: this.state.openPrimerosPasos,
                elements: [
                  {
                    text: "Acerca de",
                    action: () => {
                      this.changeDoc("primeros_pasos");
                    }
                  },
                  {
                    text: "Descarga",
                    action: () => {
                      this.changeDoc("descarga");
                    }
                  },
                  {
                    text: "Registro",
                    action: () => {
                      this.changeDoc("registro");
                    }
                  },
                  ,
                  {
                    text: "Conceptos clave",
                    action: () => {
                      this.changeDoc("conceptos_clave");
                    }
                  }
                ]
              }
            },
            {
              text: "Babel Web",
              action: () =>
                this.setOpen("openBabelWeb", !this.state.openBabelWeb),
              children: {
                open: this.state.openBabelWeb,
                elements: [
                  {
                    text: "Introducción",
                    action: () => this.changeDoc("introduccion_web")
                  },
                  {
                    text: "Mis lecciones",
                    action: () => this.changeDoc("mis_lecciones")
                  },
                  {
                    text: "Salones",
                    action: () => this.changeDoc("salones")
                  }
                ]
              }
            },
            {
              text: "Babel AR",
              action: () => {
                this.setOpen("openBabelAR", !this.state.openBabelAR);
              },
              children: {
                open: this.state.openBabelAR,
                elements: [
                  {
                    text: "¿Qué es Babel AR?",
                    action: () => this.changeDoc("babel_ar")
                  },
                  {
                    text: "Lecciones",
                    action: () => this.changeDoc("babel_ar_lecciones")
                  }
                ]
              }
            }
          ]
        }
      }
    ];
  };

  render() {
    const { classes } = this.props;
    return (
      <main className={isMobile ? classes.mobileContainer : classes.content}>
        <Drawer list={this.list()}>
          <div className={isMobile ? classes.rootMobile : classes.root}>
            <MDRenderer source={this.state.currentDoc} />
          </div>
        </Drawer>
      </main>
    );
  }
}

Docs.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Docs);
