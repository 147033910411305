import React, { Component } from "react";
import styles from "./createClassroom-styles";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { addData, getData, editData } from "./createClassroom-controller";
import SignedRoute from "../../../common/signedRoute";
import TextField from "@material-ui/core/TextField";
import DialogsInfo from "../../../common/dialogsInfo";
import CopyIcon from "@material-ui/icons/FileCopyOutlined";
import Paper from "@material-ui/core/Paper";
import { Typography, Tooltip, CircularProgress } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import CopyToClipboard from "react-copy-to-clipboard";
import Snackbar from "@material-ui/core/Snackbar";
import history from "../../../common/history";
import CardManagement from "../../../common/dynamicCardManagement/cardManagement";
import DialogCreator from "../../../common/dialog/dialogCreator";

import { getInvitation } from "../../../../backend-communication/classroom-controller";

import {
  ShareOutlined,
  EditOutlined,
  DeleteOutline,
  VisibilityOutlined,
  AddCircleOutline
} from "@material-ui/icons";
import { cancelRequests } from "../../../../backend-communication/cancel-axios-token";

const dialogs = new DialogsInfo();

class ManageClassrooms extends Component {
  state = {
    rows: [],
    el: {
      id: "",
      name: undefined,
      creationDate: undefined,
      imageURL: undefined
    },
    pageLoading: true,
    loading: false,
    shareCode: {},
    copied: false,
    dialogOpen: false,
    dialogAction: null,
    loadingShareLink: false,
    dialogContent: () => this.dialogForm(),
    dialog: dialogs.genericDialog("empty")
  };

  componentDidMount() {}

  showShareCode = el => {
    this.setState(
      {
        dialog: dialogs.genericDialog("Invitar"),

        dialogContent: this.invitationDialog,
        loadingShareLink: true,
        shareCode: ""
      },
      () => {
        this.openDialog();
        getInvitation(el.id, (err, shareCode) => {
          this.setState({ shareCode, loadingShareLink: false });
        });
      }
    );
  };

  openDialog = () => {
    this.setState({ dialogOpen: true });
  };

  closeDialog = () => {
    this.setState({ dialogOpen: false });
  };

  onClickCopy = ({ target: { innerHTML } }) => {
    console.log(`Clicked on "${innerHTML}"!`); // eslint-disable-line
  };

  onCopy = () => {
    this.setState({ copied: true });
  };

  getData = filters => {
    this.setState({ loading: true });
    getData(filters, rows => {
      this.setState({ rows, loading: false, pageLoading: false });
    });
  };

  onElementTextChange = (e, variable) => {
    let el = { ...this.state.el };
    el[variable] = e.target.value;
    this.setState({ el });
  };

  startAdd = el => {
    this.setState({ el: { name: "", imageURL: "" }, dialogOpen: true });
  };

  startEdit = el => {
    let newEl = { ...el };
    if (newEl.imageURL == null) {
      newEl.imageURL = "";
    }
    this.setState({ el: newEl, dialogOpen: true });
  };
  onAdd = () => {
    this.setState({ loading: true });
    addData({ ...this.state.el }, (err, el) => {
      this.setState({ rows: [...this.state.rows, el], loading: false });
    });
  };

  onEdit = () => {
    this.setState({ loading: true });
    editData(this.state.el.id, this.state.el, el => {
      let rows = [...this.state.rows];
      rows.forEach(i => {
        if (i.id === el.id) {
          i.name = el.name;
          i.imageURL = el.imageURL;
        }
      });
      this.setState({ rows, loading: false });
    });
  };

  componentWillUnmount() {
    cancelRequests("Unmounting");
  }

  render() {
    const { classes } = this.props;
    const { rows } = this.state;
    return (
      <React.Fragment>
        <SignedRoute role="public" executeOnValid={this.getData}>
          <Typography variant="h5" align={"left"} className={classes.title}>
            Salones
            <IconButton
              disabled={this.state.pageLoading}
              className={classes.title}
              onClick={() => {
                {
                  this.setState(
                    {
                      dialog: dialogs.addDialog("salón"),
                      dialogContent: this.dialogForm,
                      dialogAction: this.onAdd
                    },
                    () => {
                      this.startAdd();
                    }
                  );
                }
              }}
            >
              <AddCircleOutline />
            </IconButton>
          </Typography>
          <br />
          <CardManagement
            titleFieldName={"name"}
            thumbnailFieldName={"imageURL"}
            rows={rows}
            cardActions={this.renderExtras}
            loading={this.state.pageLoading}
          />
          <DialogCreator
            open={this.state.dialogOpen}
            dialogInfo={this.state.dialog}
            dialogContent={this.state.dialogContent}
            handleClose={this.closeDialog}
            handleAction={this.state.dialogAction}
          />
        </SignedRoute>
      </React.Fragment>
    );
  }

  renderExtras = el => {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <Tooltip title={"Ver"}>
          <IconButton
            onClick={() => {
              history.push({
                pathname: "/classroom",
                search: `?id=${el.id}`
              });
            }}
          >
            <VisibilityOutlined className={classes.extras} />
          </IconButton>
        </Tooltip>

        <Tooltip title={"Invitar"}>
          <IconButton
            onClick={() => {
              this.showShareCode(el);
            }}
          >
            <ShareOutlined className={classes.extras} />
          </IconButton>
        </Tooltip>

        <Tooltip title={"Editar"}>
          <IconButton
            onClick={() => {
              this.setState(
                {
                  dialog: dialogs.editDialog("salón"),
                  dialogContent: this.dialogForm,
                  dialogAction: this.onEdit
                },
                () => {
                  this.startEdit(el);
                }
              );
            }}
          >
            <EditOutlined className={classes.extras} />
          </IconButton>
        </Tooltip>

        {/*<Tooltip title={"Remove"}>
          <IconButton
            onClick={() => {
              history.push("/classroom", {
                classroom: el
              });
            }}
          >
            <DeleteOutline className={classes.extras} />
          </IconButton>
          </Tooltip>*/}
      </React.Fragment>
    );
  };

  dialogForm = () => {
    return (
      <React.Fragment>
        <TextField
          autoFocus
          required
          margin="dense"
          id="name"
          label="Nombre"
          type="text"
          autoComplete="off"
          onChange={e => this.onElementTextChange(e, "name")}
          value={this.state.el.name}
          fullWidth
          variant="outlined"
        />
        <TextField
          autoFocus
          required
          margin="dense"
          id="Image url"
          label="Url de Imagen"
          type="text"
          autoComplete="off"
          onChange={e => this.onElementTextChange(e, "imageURL")}
          value={this.state.el.imageURL}
          fullWidth
          variant="outlined"
        />
      </React.Fragment>
    );
  };

  invitationDialog = () => {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <Paper className={classes.shareLinkContainer}>
          {this.state.loadingShareLink ? (
            <CircularProgress />
          ) : (
            <Typography>{this.state.shareCode}</Typography>
          )}
          <div className={classes.grow} />
          <Divider className={classes.divider} orientation="vertical" />

          <CopyToClipboard
            onCopy={this.onCopy}
            options={{ message: "Copied" }}
            text={this.state.shareCode}
            disabled={this.state.loadingShareLink}
          >
            <IconButton
              color="primary"
              className={classes.iconButton}
              aria-label="directions"
              onClick={this.onClickCopy}
            >
              <CopyIcon />
            </IconButton>
          </CopyToClipboard>
        </Paper>

        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right"
          }}
          open={this.state.copied}
          autoHideDuration={1000}
          onClose={e => {
            this.setState({ copied: false });
          }}
          ContentProps={{
            "aria-describedby": "message-id"
          }}
          message={<span id="message-id">Copied to clipboard</span>}
        />
      </React.Fragment>
    );
  };
}

ManageClassrooms.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ManageClassrooms);
