import React, { Component } from "react";
import styles from "./filterItem-style";
import { FormGroup } from "@material-ui/core";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Switch from "@material-ui/core/Switch";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";

class FilterItem extends Component {
  state = {
    enabled: false,
    dir: null,
    switchIsActive: true
  };

  isEnabled() {
    return this.state.enabled;
  }

  automaticallyEnable = hasFilterData => {
    this.setState({ enabled: hasFilterData, switchIsActive: hasFilterData });
  };

  /**
   * Event for the switch (enabled/disables the filter)
   */
  handleChangeEvent = event => {
    this.setState({ enabled: event.target.checked });
  };

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <div className={classes.container}>
          {this.props.children}
          <span className={classes.switch}>
            <Switch
              disabled={!this.state.switchIsActive}
              checked={this.state.enabled}
              onChange={this.handleChangeEvent}
              value="checked"
            />
          </span>
        </div>
      </React.Fragment>
    );
  }
}

FilterItem.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(FilterItem);
