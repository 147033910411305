import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import styles from "./selectTextfield-styles";

class SelectTextField extends Component {
  state = {};

  render() {
    const { classes, suggestions, variables, label, margin } = this.props;
    return (
      <TextField
        id="outlined-select-currency"
        select
        fullWidth
        margin={margin}
        label={label}
        className={classes.textField}
        value={this.props.value}
        onChange={this.props.onChange}
        SelectProps={{
          MenuProps: {
            className: classes.menu
          }
        }}
        margin="normal"
        variant="outlined"
      >
        {suggestions.map(option => (
          <MenuItem key={option[variables.id]} value={option[variables.id]}>
            {option[variables.label]}
          </MenuItem>
        ))}
      </TextField>
    );
  }
}

SelectTextField.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(SelectTextField);
