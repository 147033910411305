import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import FilterText from "./filters/filter-text";

class FilterDialog extends Component {
  handleClose = () => {
    if (this.props.handleClose) {
      this.props.handleClose();
    }
  };

  getFilters = () => {
    var filters = [];
    var noExtraFilters = [];
    if (this.props.extraFilters) filters = [...this.props.extraFilters];
    const { refs } = this.props;
    if (this.props.refs) {
      for (let el of refs) {
        var filter = el.current.getFilter();
        if (filter != null) {
          filters.push(filter);
          noExtraFilters.push(filter);
        }
      }
    }

    if (this.props.getFilters) {
      this.props.getFilters(noExtraFilters);
    }

    if (this.props.filter) {
      this.props.filter(filters);
    }
  };

  render() {
    return (
      <Dialog
        fullWidth
        open={this.props.open}
        onClose={this.handleClose}
        aria-labelledby="form-dialog-titlekeepMounted"
        keepMounted
      >
        <DialogTitle id="form-dialog-title">Filters</DialogTitle>
        <DialogContent>{this.props.children} </DialogContent>

        <DialogActions>
          <Button
            onClick={() => {
              this.handleClose();
              this.getFilters();
            }}
            color="primary"
          >
            Apply
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default FilterDialog;
