import axios from "axios";

export function getData(filters, callback) {
  var rows = [];
  axios
    .get("/api/getAllSubjects", { params: { filters: filters } })
    .then(res => {
      if (res.data.err == null) {
        res.data.data.forEach(el => {
          rows.push({
            id: el.id,
            subject: el.subject,
            imageURL: el.imageURL
          });
        });
        callback(rows);
      }
    });
}

export function addData(el, callback) {
  axios.post("/api/addSubject", { subject: el }).then(res => {
    callback({
      id: res.data.data,
      subject: el.subject,
      imageURL: el.imageURL
    });
  });
}

export function editData(id, el, callback) {
  axios.put(`/api/editSubject/${id}`, { subject: el }).then(res => {
    callback({ id: el.id, subject: el.subject, imageURL: el.imageURL });
  });
}
