export default theme => ({
  container: {
    marginTop: 15,
    display: "flex"
  },

  switch: {
    marginLeft: "auto",
    marginRight: 10,
    paddingLeft: 70
  }
});
