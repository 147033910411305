import React, { Component } from "react";
import PropTypes from "prop-types";
import deburr from "lodash/deburr";
import Downshift from "downshift";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Popper from "@material-ui/core/Popper";
import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";
import Chip from "@material-ui/core/Chip";
import styles from "./autocomplete-styles";
import Menu from "@material-ui/core/Menu";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

function renderInput(inputProps) {
  const { InputProps, classes, ref, ...other } = inputProps;

  return (
    <TextField
      autoFocus
      InputProps={{
        inputRef: ref,
        classes: {
          root: classes.inputRoot,
          input: classes.inputInput
        },
        ...InputProps
      }}
      {...other}
    />
  );
}

function renderSuggestion(
  { suggestion, index, itemProps, highlightedIndex, selectedItem },
  variables
) {
  const isHighlighted = highlightedIndex === index;
  let isSelected = false;
  if (selectedItem) isSelected = selectedItem.id === suggestion[variables.id];

  return (
    <MenuItem
      {...itemProps}
      key={suggestion[variables.id]}
      selected={isHighlighted}
      style={{
        fontWeight: isSelected ? 500 : 400,
        height: 20
      }}
    >
      {suggestion[variables.label]}
    </MenuItem>
  );
}

renderSuggestion.propTypes = {
  highlightedIndex: PropTypes.number,
  index: PropTypes.number,
  itemProps: PropTypes.object,
  selectedItem: PropTypes.string,
  suggestion: PropTypes.shape({ label: PropTypes.string }).isRequired
};

function getSuggestions(value, suggestions, variables) {
  const inputValue = deburr(value.trim()).toLowerCase();
  const inputLength = inputValue.length;
  let count = 0;

  return inputLength === 0
    ? suggestions
    : suggestions.filter(suggestion => {
        const keep =
          count < 5 &&
          suggestion[variables.label]
            .toLowerCase()
            .includes(inputValue.toLowerCase());

        if (keep) {
          count += 1;
        }

        return keep;
      });
}

let popperNode;

class IntegrationDownshift extends Component {
  state = { inputValue: "", selectedItem: [] };

  handleInputChange = event => {
    this.setState({ inputValue: event.target.value });
  };

  handleChange = selection => {
    //this.setState({ inputValue: selection.label });
    if (this.props.onChange) {
      this.props.onChange(selection);
    }
  };

  onClick = () => {
    //this.setState({ inputValue: "" });
  };

  onBlur = selection => {
    const value = selection.label;
    this.setState({ inputValue: value });
  };

  render() {
    const props = this.props;
    const { classes } = props;
    return (
      <div className={classes.root}>
        <Downshift
          id="downshift-popper"
          onChange={this.handleChange}
          itemToString={i => {
            return i ? i.label : "";
          }}
        >
          {({
            getInputProps,
            getItemProps,
            getMenuProps,
            highlightedIndex,
            inputValue,
            isOpen,
            selectedItem,
            clearSelection
          }) => (
            <div className={classes.container}>
              {renderInput({
                fullWidth: true,
                classes,
                variant: "outlined",
                InputProps: getInputProps({
                  onClick: () => {
                    //inputValue = "";
                    this.onClick();
                  },
                  placeholder: props.label,
                  className: classes.input,
                  classes: {
                    root: classes.cssOutlinedInput,
                    focused: classes.cssFocused,
                    notchedOutline: classes.notchedOutline,
                    input: classes.input
                  }
                }),
                ref: node => {
                  popperNode = node;
                }
              })}
              <Popper open={isOpen} anchorEl={popperNode}>
                <div
                  {...(isOpen
                    ? getMenuProps({}, { suppressRefError: true })
                    : {})}
                >
                  <Paper
                    square={false}
                    elevation={4}
                    style={{
                      maxHeight: 48 * 4.5,
                      marginTop: 5,
                      width: popperNode ? popperNode.clientWidth : null,
                      overflow: "auto"
                    }}
                  >
                    <List className={classes.list}>
                      {getSuggestions(
                        inputValue,
                        props.suggestions,
                        props.variables
                      ).map((suggestion, index) =>
                        renderSuggestion(
                          {
                            suggestion,
                            index,
                            itemProps: getItemProps({
                              item: {
                                id: suggestion[props.variables.id],
                                label: suggestion[props.variables.label]
                              }
                            }),
                            highlightedIndex,
                            selectedItem
                          },
                          props.variables
                        )
                      )}
                    </List>
                  </Paper>
                </div>
              </Popper>
            </div>
          )}
        </Downshift>
      </div>
    );
  }
}

IntegrationDownshift.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(IntegrationDownshift);
