import { makeStyles } from "@material-ui/styles";

require("typeface-rubik");
require("typeface-josefin-sans");

export default theme => ({
  root: {
    textAlign: "justify",
    padding: 50
  },
  rootMobile: {
    textAlign: "justify",
    padding: 10
  }
});

export const useStyles = makeStyles(theme => ({
  title: {
    color: theme.palette.text.primary,
    fontFamily: "Josefin Sans",
    fontWeight: 500
  },
  h1: { fontSize: "3em" },
  h2: { fontSize: "2.5em" },
  h3: { fontSize: "2em" },
  h4: { fontSize: "1.8em" },
  h5: { fontSize: "1.6em" },
  h6: { fontSize: "1.4em" },
  subtitle: {},
  content: {
    color: theme.palette.text.secondary,
    fontSize: "1.1em",
    fontWeight: 400,
    fontFamily: "Rubik"
  },
  contentBold: {}
}));
