import React, { Component } from "react";
import HomeAdmin from "./homeAdmin";
import Courses from "./lessons/courses/courses";
class ManageLessons extends Component {
  state = {};

  render() {
    return (
      <HomeAdmin>
        <Courses />
      </HomeAdmin>
    );
  }
}

export default ManageLessons;
