import React, { Component } from "react";
import PublicMain from "./main/publicMain";
import NavigateLessons from "./navigateLessons/navigateLessons";
import SignedRoute from "../common/signedRoute";

class HomePublic extends Component {
  render() {
    return (
      <SignedRoute role="public">
        <NavigateLessons />
      </SignedRoute>
    );
  }
}

export default HomePublic;
