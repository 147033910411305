export default theme => ({
  content: { padding: theme.spacing(3) },
  iconButton: {
    padding: 10
  },
  divider: {
    height: 28,
    margin: 4
  },
  extras: {
    color: "white"
  },
  title: {
    color: theme.palette.text.main
  },
  media: {
    height: 0,
    paddingTop: "75%" // 16:9
  },
  card: {
    backgroundColor: "white"
  },
  header: {
    fontSize: 12
  }
});
