import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import DynamicTable from "../../common/dynamicTable/dynamicTable";
import TextField from "@material-ui/core/TextField";

import DialogsInfo from "../../common/dialogsInfo";
import {
  getAllClassrooms as getData,
  verify
} from "../../../backend-communication/classroom-controller";

import FilterDialog from "../../common/filter/filterDialog";
import FilterText from "../../common/filter/filters/filter-text";

import AutoFitImage from "react-image-autofit-frame";
import styles from "./subjects-styles";
import { CheckCircle } from "@material-ui/icons";
import { IconButton } from "@material-ui/core";

const dialogs = new DialogsInfo();

class Classroom extends Component {
  state = {
    el: { id: "", name: "", imageURL: "", verified: "" },
    rows: [],
    filterOpen: false,
    loading: false
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.getData();
  }

  onElementTextChange = (e, variable) => {
    let el = { ...this.state.el };
    el[variable] = e.target.value;
    this.setState({ el });
  };

  dialogForm = () => {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Subject name"
          type="text"
          autoComplete="off"
          onChange={e => this.onElementTextChange(e, "subject")}
          value={this.state.el.subject}
          fullWidth
          variant="outlined"
        />

        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Image URL"
          type="text"
          autoComplete="off"
          onChange={e => this.onElementTextChange(e, "imageURL")}
          value={this.state.el.imageURL}
          fullWidth
          variant="outlined"
        />
        <div style={{ height: 20 }} />
        <AutoFitImage
          frameWidth="500px"
          frameHeight="400px"
          imgSrc={this.state.imageURL}
        />
      </React.Fragment>
    );
  };

  deleteDialogForm = () => {
    return (
      <span>Are you sure you want to delete: {this.state.el.subject}</span>
    );
  };

  getData = filters => {
    this.setState({ loading: true });
    getData(filters, (err, rows) => {
      rows = rows.map(el => {
        return { ...el, verifiedStr: el.verified ? "✔ - Yes" : "" };
      });

      this.setState({ rows: rows, loading: false });
    });
  };

  extras = el => {
    let id = el.id;
    return (
      <IconButton>
        <CheckCircle
          onClick={() => {
            this.setState({ loading: true });
            verify(id, err => {
              this.getData();
            });
          }}
        />
      </IconButton>
    );
  };

  render() {
    return (
      <React.Fragment>
        <DynamicTable
          title="Classrooms"
          header={["Identifier", "Classroom", "Image URL", "Verified"]}
          loading={this.state.loading}
          rows={this.state.rows}
          model={["id", "name", "imageURL", "verifiedStr"]}
          extras={this.extras}
        />
      </React.Fragment>
    );
  }
}

Classroom.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Classroom);
