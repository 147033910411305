import axios from "axios";
var dateFormat = require("dateformat");

export function getData(filters, callback) {
  var rows = [];
  axios
    .get("/api/lessonHistory", {
      params: { filters: filters }
    })
    .then(res => {
      if (res.data.err == null) {
        res.data.data.forEach(el => {
          rows.push({
            lessonID: el.lessonID,
            lessonName: el.lessonName,
            completionDate: dateFormat(el.completionDate, "dd/mm/yyyy")
          });
        });
        callback(rows);
      }
    });
}
