export default theme => ({
  appBar: {
    position: "relative",
    backgroundColor: theme.palette.background.darker
  },
  icon: {
    marginRight: theme.spacing(2)
  },
  mainTitle: {
    color: "white"
  },
  heroUnit: {
    background: theme.palette.background.dark
  },
  heroContent: {
    maxWidth: 600,
    margin: "0 auto",
    padding: `${theme.spacing(8)}px 0 ${theme.spacing(6)}px`
  },
  heroContentMobile: {
    margin: "20 auto",
    padding: `${theme.spacing(8)}px 0 ${theme.spacing(1)}px`,
    marginLeft: 20,
    marginRight: 20
  },
  heroButtons: {
    marginTop: theme.spacing(4)
  },
  main: {
    backgroundColor: theme.palette.background.darker
  },
  layout: {
    width: "auto",
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(1100 + theme.spacing(3 * 2))]: {
      width: 1100,
      marginLeft: "auto",
      marginRight: "auto"
    }
  },
  cardGrid: {
    padding: `${theme.spacing(8)}px 0`
  },
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    backgroundColor: theme.palette.background.light,
    borderRadius: 10,
    minHeight: 300
  },
  cardMobile: {
    width: "90%",
    height: "100%",
    display: "flex",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: 10,
    flexDirection: "column",
    backgroundColor: theme.palette.background.light
  },
  cardMedia: {
    paddingTop: "75%" // 16:9
  },
  cardContent: {
    flexGrow: 1
  },
  cardButton: {
    color: theme.palette.primary.tone1
  },
  footer: {
    backgroundColor: theme.palette.background.darker,
    padding: theme.spacing(6)
  },
  actions: {
    display: "flex"
  },
  avatar: {
    width: 25,
    height: 25,
    backgroundColor: theme.palette.secondary.dark,
    color: theme.palette.primary.font
  },
  chip: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.tone3,
    color: theme.palette.primary.font
  },
  cardHeader: {
    textAlign: "left",

    backgroundColor: theme.palette.background.main,
    color: theme.palette.text.light
  },
  title: {
    color: theme.palette.text.main,
    fontSize: 18
  },
  footerFont: {
    color: theme.palette.text.dark
  },
  dialog: { minWidth: "30%" },
  qrContainer: {
    marginLeft: "auto",
    marginRight: "auto"
  },
  qr: { width: "100%" },
  buttonContainer: {
    marginTop: 20,
    marginLeft: "auto",
    marginRight: "auto",
    textAlign: "center"
  },
  dialogTitle: {
    marginLeft: theme.spacing(2),
    flex: 1
  },
  lessonContent: {
    padding: 50
  },
  gridList: {
    width: 1000,
    height: 600
  },
  cardTitle: {
    color: theme.palette.text.secondary
  }
});
