import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import SearchIcon from "@material-ui/icons/Search";
import FilterIcon from "@material-ui/icons/FilterListTwoTone";
import styles from "./searchbar-styles";

function Searchbar(props) {
  const { classes } = props;

  return (
    <Paper className={classes.root} elevation={1}>
      {/*<IconButton className={classes.iconButton} aria-label="Menu">
        <MenuIcon />
      </IconButton>*/}
      <InputBase
        className={classes.input}
        placeholder="Search"
        value={props.value}
        onChange={props.onChange}
      />
      <IconButton
        className={classes.iconButton}
        aria-label="Search"
        onClick={props.onSearch}
      >
        <SearchIcon />
      </IconButton>
      <Divider className={classes.divider} />
      <IconButton
        className={classes.iconButton}
        aria-label="Filter"
        onClick={() => {
          if (props.filterAction) props.filterAction();
        }}
      >
        <FilterIcon />
      </IconButton>
    </Paper>
  );
}

Searchbar.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Searchbar);
