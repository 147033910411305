import firebase, { auth } from "firebase";
import axios from "axios";
import signedRoute from "../common/signedRoute";
import { getCancelToken } from "../../backend-communication/cancel-axios-token";

/**
 * @callback AuthorizationCallback
 * @param {boolean} hasAuthorization
 */

/**
 * @callback GetFirebaseInfoCallback
 * @param {Error} error
 * @param {firebae.UserInfo} userInfo
 */

/**
 * @callback GetUserInfoCallback
 * @param {Error} error
 * @param {UserInfo} userInfo
 */

class AuthMngr {
  /**
   * Signleton instance
   */
  static instance = null;

  /**
   * @returns {AuthMngr} Instance
   */
  static getInstance() {
    if (AuthMngr.instance == null) AuthMngr.instance = new AuthMngr();
    return this.instance;
  }

  /**
   * Constructor for the singleton
   * Adds a firebase listener for auth changes, this listener logs in and out in memory
   */
  constructor() {
    firebase.auth().onAuthStateChanged(authUser => {
      if (authUser) {
        firebase
          .auth()
          .currentUser.getIdToken()
          .then(idToken => {
            axios.defaults.headers.common["Authorization"] = idToken;
            this.init = true;
            this.isSigned = true;
            this.onLogin(idToken);
          });
      } else {
        delete axios.defaults.headers.common["Authorization"];
        this.init = true;
        this.isSigned = false;
        this.onLogin(null);
      }
    });
  }

  init = false;
  isSigned = false;
  loginEvents = [];
  oneTimeEvents = [];

  onLogin(idToken) {
    this.loginEvents.forEach(event => {
      event(idToken);
    });
  }

  signIn(user, callback) {
    user.getIdToken(true).then(idToken => {
      axios
        .get(`/api/signIn`, { cancelToken: getCancelToken().token })
        .then(res => {
          callback(res);
        });
    });
  }

  subscribeEvent(_event) {
    if (_event === null) return;
    this.loginEvents.push(_event);
  }

  subscribeOrExecute(_event) {
    if (this.init) {
      _event();
      return null;
    }
    this.subscribeEvent(_event);
    return _event;
  }

  unsubscribeEvent(_event) {
    if (_event === null) return;
    this.loginEvents = this.loginEvents.filter((value, index, arr) => {
      return value !== _event;
    });
  }

  signOut(callback) {
    firebase
      .auth()
      .signOut()
      .then(() => {
        //this.loginEvents.length = 0;
        this.isSigned = false;
        callback();
      });
  }

  hasEmailVerification() {
    return firebase.auth().currentUser.emailVerified;
  }

  resendVerification() {
    firebase.auth().currentUser.sendEmailVerification();
  }

  /**
   *
   * @param {GetFirebaseInfoCallback} callback
   */
  getFirebaseUserInfo(callback) {
    if (!this.isSigned) {
      callback(null);
      return;
    }
    callback(firebase.auth().currentUser);
  }

  /**
   *
   * @param {string} role "admin","public"
   * @param {AuthorizationCallback} callback hasAuthorization
   */
  hasAuthorization(role, callback) {
    axios
      .get(`/api/hasAuthorization/${role}`, {
        cancelToken: getCancelToken().token
      })
      .then(res => {
        callback(res.data);
      })
      .catch(err => {});
  }

  /**
   *
   * @param {string} email
   * @param {Error} callback
   */
  forgotPassword(email, callback) {
    firebase
      .auth()
      .sendPasswordResetEmail(email)
      .catch(callback);
  }

  /**
   *
   * @param {GetUserInfoCallback} callback err, userInfo{name,imageURL,points}
   */
  getUserInfo(callback) {}
}

/**
 @typedef UserInfo
 @type {Object}
 @property {firebase.User} user Information of the account
 @property {number} points Points of the user
 */

export default AuthMngr;
