import React, { Component } from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import styles from "./arpolicies-styles";
import history from "../../common/history";
import { isMobile, isAndroid } from "mobile-device-detect";
import DownloadIcon from "@material-ui/icons/ArrowDownward";
import AuthMngr from "../../login/authmngr";
import { Container } from "@material-ui/core";
import { MDRenderer } from "../../docs/md-renderer";
import { getMarkdown } from "../../docs/docs-controller";

class ARPolicies extends Component {
  state = { currentDoc: "" };
  componentDidMount() {
    getMarkdown("arpolicies", res => {
      this.setState({ currentDoc: res });
    });
  }

  render() {
    const { classes } = this.props;
    return (
      <Container maxWidth="md" className={classes.root}>
        <MDRenderer source={this.state.currentDoc} />
      </Container>
    );
  }
}

export default withStyles(styles)(ARPolicies);
