export default theme => ({
  root: {
    paddingTop: 20,
    textAlign: "justify",
    align: "left"
  },
  appBar: {
    position: "absolute",
    backgroundColor: theme.palette.background.paper,
    boxShadow: "none",
    color: theme.palette.text.primary
  },
  icon: {
    marginRight: theme.spacing(2)
  },
  textTitle: {
    color: theme.palette.text.primary,
    fontWeight: 500
  },
  textDescription: {
    color: theme.palette.text.primary,
    fontWeight: 400
  },
  moreButton: {
    color: theme.palette.text.primary
  },
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper
  }
});

/*
Photo by Shubham Sharan on Unsplash

<a style="background-color:black;color:white;text-decoration:none;padding:4px 6px;font-family:-apple-system, BlinkMacSystemFont, &quot;San Francisco&quot;, &quot;Helvetica Neue&quot;, Helvetica, Ubuntu, Roboto, Noto, &quot;Segoe UI&quot;, Arial, sans-serif;font-size:12px;font-weight:bold;line-height:1.2;display:inline-block;border-radius:3px" href="https://unsplash.com/@shubhamsharan?utm_medium=referral&amp;utm_campaign=photographer-credit&amp;utm_content=creditBadge" target="_blank" rel="noopener noreferrer" title="Download free do whatever you want high-resolution photos from Shubham Sharan"><span style="display:inline-block;padding:2px 3px"><svg xmlns="http://www.w3.org/2000/svg" style="height:12px;width:auto;position:relative;vertical-align:middle;top:-2px;fill:white" viewBox="0 0 32 32"><title>unsplash-logo</title><path d="M10 9V0h12v9H10zm12 5h10v18H0V14h10v9h12v-9z"></path></svg></span><span style="display:inline-block;padding:2px 3px">Shubham Sharan</span></a>


*/
