import React, { Component } from "react";
import Drawer from "@material-ui/core/Drawer";
import CssBaseline from "@material-ui/core/CssBaseline";
import List from "@material-ui/core/List";
import {
  Divider,
  Fab,
  Breadcrumbs,
  Paper,
  MenuList,
  MenuItem
} from "@material-ui/core";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import { ExpandLess, Menu } from "@material-ui/icons";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import styles from "./docs-drawer-styles";
import { isMobile, isAndroid } from "mobile-device-detect";
import TreeView from "@material-ui/lab/TreeView";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import TreeItem from "@material-ui/lab/TreeItem";
const uuidv1 = require("uuid/v1");

class PermanentDrawerLeft extends Component {
  state = { open: false, openBabelWeb: false, openBabelAR: false };

  renderDesktop() {}

  render() {
    const { classes } = this.props;
    const { open = false } = this.state;
    const variant = isMobile ? undefined : "permanent";
    const drawerClass = isMobile ? classes.drawerMobile : classes.drawerPaper;

    return (
      <React.Fragment>
        <div className={isMobile ? classes.mobileRoot : classes.root}>
          {isMobile && (
            <Paper
              className={classes.treeView}
              defaultCollapseIcon={<ExpandMoreIcon />}
              defaultExpandIcon={<ChevronRightIcon />}
            >
              <MenuList>
                {this.props.list.map((item, index) =>
                  this.renderTreeView(item, index)
                )}
              </MenuList>
            </Paper>
          )}
          {!isMobile && (
            <Drawer
              className={classes.drawer}
              variant={variant}
              classes={{
                paper: drawerClass
              }}
              anchor="left"
              open={open}
              onClose={() => this.setState({ open: false })}
            >
              <div className={classes.toolbar}>
                <h3 className={classes.abbBarTxt}>Manual</h3>
              </div>

              <Divider />
              <List>
                {this.props.list.map((item, index) =>
                  this.renderListItem(item, index)
                )}
              </List>
            </Drawer>
          )}
          <main className={classes.content}>{this.props.children}</main>
        </div>
      </React.Fragment>
    );
  }

  renderTreeView(item, index, style) {
    /*
              <TreeItem nodeId="2" label="Calendar" />
              <TreeItem nodeId="3" label="Chrome" />
              <TreeItem nodeId="4" label="Webstorm" />*/
    const { classes } = this.props;
    if (item.children == null) {
      return (
        <MenuItem nodeId={uuidv1()} onClick={item.action} label={item.text} />
      );
    } else {
      return (
        <React.Fragment>
          <ListItem
            button
            key={uuidv1()}
            className={style}
            onClick={item.action}
          >
            <ListItemText primary={item.text} />
            {item.children.open ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={item.children.open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {item.children.elements.map((el, id) =>
                this.renderListItem(el, id, classes.nested)
              )}
            </List>
          </Collapse>
        </React.Fragment>
      );
    }
  }

  renderListItem(item, index, style) {
    const { classes } = this.props;
    if (item.children == null) {
      return (
        <ListItem button key={uuidv1()} className={style} onClick={item.action}>
          <ListItemText primary={item.text} />
        </ListItem>
      );
    } else {
      return (
        <React.Fragment>
          <ListItem
            button
            key={uuidv1()}
            className={style}
            onClick={item.action}
          >
            <ListItemText primary={item.text} />
            {item.children.open ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={item.children.open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {item.children.elements.map((el, id) =>
                this.renderListItem(el, id, classes.nested)
              )}
            </List>
          </Collapse>
        </React.Fragment>
      );
    }
  }
}
PermanentDrawerLeft.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(PermanentDrawerLeft);
