import axios from "axios";
import { getCancelToken } from "./cancel-axios-token";

/**
 * @typedef {import('../typedefs').FilterInfo} FilterInfo
 * @typedef {import('../../addons/sharedTypedef').AddCallback} AddCallback
 * @typedef {import('../../addons/sharedTypedef').EditCallback} EditCallback
 * @typedef {import('../../addons/sharedTypedef').RemoveCallback} RemoveCallback
 * @typedef {import('../../addons/sharedTypedef').StringCallback} StringCallback
 */

/**
 @typedef {Object} Classroom
 @property {String} id 
 @property {String} name
 @property {String} imageURL
 */

/**
 @typedef {Object} Member
 @property {String} name
 @property {String} photoURL
 @property {String} points
 */

/**
 * @callback GetArrayCallback
 * @param {Error} error
 * @param {Array<Classroom>} lessons
 */

/**
 * @callback GetSingleCallback
 * @param {Error} error
 * @param {Classroom} lesson
 */

/**
 * @callback GetMembersArrayCallback
 * @param {Error} error
 * @param {Array<Member>} lessons
 */

/**
 * @callback GetMembersSingleCallback
 * @param {Error} error
 * @param {Member} lesson
 */

/**
 * Lessons created by the current logged user
 * @param {string} invitationId
 * @param {StringCallback} callback
 */

export function joinClassroom(invitationId, callback) {
  axios
    .get(`/api/alreadyJoin/${invitationId}`, {
      cancelToken: getCancelToken().token
    })
    .then(res => {
      const { err, val, id } = res.data;
      if (val) {
        //already joined
        callback(err, id);
      } else {
        //join
        axios
          .post(
            "/api/joinClassroom",
            { invitationId },
            { cancelToken: getCancelToken().token }
          )
          .then(res => {
            callback(res.data.err, res.data.data);
          });
      }
    })
    .catch(err => {
      if (axios.isCancel(err)) {
      } else {
      }
    });
}

export function belongsToClassroom(id, callback) {
  axios
    .get(`/api/belongsToClassroom/${id}`, {
      cancelToken: getCancelToken().token
    })
    .then(res => {
      const { val } = res.data;
      callback(val);
    })
    .catch(err => {
      if (axios.isCancel(err)) {
      } else {
      }
    });
}

/**
 *
 * @param {string} id
 * @param {StringCallback} callback
 */
export function getInvitation(id, callback) {
  axios
    .post(
      "/api/getInvitation",
      { classroomId: id },
      { cancelToken: getCancelToken().token }
    )
    .then(res => {
      callback(res.data.err, res.data.data);
    });
}

/**
 *
 * @param {FilterInfo} filters
 * @param {GetArrayCallback} callback
 */
export function getData(filters, callback) {
  axios
    .get("/api/getMyClassrooms", {
      filters: filters,
      cancelToken: getCancelToken().token
    })
    .then(res => {
      callback(res.data.err, res.data.data);
    })
    .catch(err => {
      if (axios.isCancel(err)) {
      } else {
      }
    });
}

/**
 *
 * @param {string} id
 * @param {GetSingleCallback} callback
 */
export function getClassroomById(id, callback) {
  axios
    .get(`/api/getClassroomById/${id}`, {
      cancelToken: getCancelToken().token
    })
    .then(res => {
      callback(res.data.err, res.data.data);
    })
    .catch(err => {
      if (axios.isCancel(err)) {
      } else {
      }
    });
}

/**
 *
 * @param {string} id
 * @param {GetMembersArrayCallback} callback
 */
export function getLeaderboard(id, callback) {
  axios
    .get(`/api/getClassroomLeaderboard/${id}`, {
      cancelToken: getCancelToken().token
    })
    .then(res => {
      callback(res.data.err, res.data.data);
    })
    .catch(err => {
      if (axios.isCancel(err)) {
      } else {
      }
    });
}

export function getClassroomLessons(id, callback) {
  axios
    .get(`/api/getClassroomLessons/${id}`, {
      cancelToken: getCancelToken().token
    })
    .then(res => {
      callback(res.data.err, res.data.data);
    })
    .catch(err => {
      if (axios.isCancel(err)) {
      } else {
      }
    });
}

export function publishLesson(classroomId, lessonId, callback) {
  axios
    .post(
      "/api/publishLesson",
      { classroomId, lessonId },
      { cancelToken: getCancelToken().token }
    )
    .then(res => {
      callback(res.data.err);
    })
    .catch(err => {
      if (axios.isCancel(err)) {
      } else {
      }
    });
}

/**
 *
 * @param {Array<FilterInfo>} filters
 * @param {GetArrayCallback} callback
 */
export function getOwnedClassrooms(filters, callback) {
  axios
    .get("/api/ownedClassrooms", {
      cancelToken: getCancelToken().token
    })
    .then(res => {
      callback(res.data.err, res.data.data);
    })
    .catch(err => {
      if (axios.isCancel(err)) {
      } else {
      }
    });
}

/**
 *
 * @param {Array<FilterInfo>} filters
 * @param {GetArrayCallback} callback
 */
export function getVerifiedClasrooms(filters, callback) {
  axios
    .get("/api/verifiedClassrooms", {
      cancelToken: getCancelToken().token
    })
    .then(res => {
      callback(res.data.err, res.data.data);
    })
    .catch(err => {
      if (axios.isCancel(err)) {
      } else {
      }
    });
}

//imTheClassroomOwner

/**
 *
 * @param {import("../typedefs").BooleanCallback} callback
 */
export function imTheClassroomOwner(classroomId, callback) {
  axios
    .get(`/api/imTheClassroomOwner/${classroomId}`, {
      cancelToken: getCancelToken().token
    })
    .then(res => {
      callback(res.data.data);
    })
    .catch(err => {
      if (axios.isCancel(err)) {
      } else {
      }
    });
}

/**
 *
 * @param {String} classroomId
 * @param {String} lessonId
 * @param {StringCallback} callback
 */
export function removeLessonFromClassroom(classroomId, lessonId, callback) {
  axios
    .delete(`/api/removeLessonFromClassroom/${lessonId}/${classroomId}`, {
      cancelToken: getCancelToken().token
    })
    .then(res => {
      callback(res.data.err, res.data.data);
    })
    .catch(err => {
      if (axios.isCancel(err)) {
      } else {
      }
    });
}

/**
 *
 * @param {Array<FilterInfo>} filters
 * @param {GetArrayCallback} callback
 */
export function getAllClassrooms(filters, callback) {
  axios
    .get("/api/getAllClassrooms", {
      cancelToken: getCancelToken().token,
      params: { filters: filters }
    })
    .then(res => {
      callback(res.data.err, res.data.data);
    })
    .catch(err => {
      if (axios.isCancel(err)) {
      } else {
      }
    });
}

export function verify(classroomId, callback) {
  axios
    .put(
      `/api/verifyClassroom/${classroomId}`,
      {},
      {
        cancelToken: getCancelToken().token
      }
    )
    .then(res => {
      callback(res.data.err, res.data.data);
    })
    .catch(err => {
      if (axios.isCancel(err)) {
      } else {
      }
    });
}
