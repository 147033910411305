export default theme => ({
  content: {
    padding: theme.spacing(3)
  },
  shareLinkContainer: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: "100%"
  },
  shareLinkText: {
    flex: 1
  },
  grow: {
    flexGrow: 1
  },
  iconButton: {
    padding: 10
  },
  divider: {
    height: 28,
    margin: 4
  },
  extras: {
    color: "white"
  },
  title: {
    color: theme.palette.text.default
  },
  media: {
    height: 0,
    paddingTop: "75%" // 16:9
  },
  card: {
    backgroundColor: theme.palette.background.darker,
    color: "white"
  },
  header: {
    fontSize: 12
  }
});
