import { getCancelToken } from "./cancel-axios-token";
import axios from "axios";
export function sendEmail(email, subject, message, callback) {
  axios
    .post(
      "/api/contact/",
      { email, subject, message },
      { cancelToken: getCancelToken().token }
    )
    .then(res => {
      callback(res.data.err, res.data.data);
    })
    .catch(err => {
      if (axios.isCancel(err)) {
      } else {
      }
    });
}
