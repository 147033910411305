import React, { Component } from "react";
import styles from "./lessonHistory-style";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { getData } from "./lessonHistory-controller";
import PublicMain from "../main/publicMain";
import SignedRoute from "../../common/signedRoute";
import DynamicTable from "../../common/dynamicTable/dynamicTable";

class LessonsHistory extends Component {
  state = { rows: [], loading: false };

  getData = filters => {
    this.setState({ loading: true });
    getData(filters, rows => {
      this.setState({ rows, loading: false });
    });
  };

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <SignedRoute role="public" executeOnValid={this.getData}>
          <PublicMain blockToolbar={true}>
            <main className={classes.content}>
              <DynamicTable
                title="History"
                header={["Lesson ID", "Lesson", "Completion Date"]}
                loading={this.state.loading}
                rows={this.state.rows}
                model={["lessonID", "lessonName", "completionDate"]}
              />
            </main>
          </PublicMain>
        </SignedRoute>
      </React.Fragment>
    );
  }
}

LessonsHistory.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(LessonsHistory);
