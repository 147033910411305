import axios from "axios";

import { getCancelToken } from "./cancel-axios-token";
/**
 * @typedef {import('../typedefs').FilterInfo} FilterInfo
 * @typedef {import('../../addons/sharedTypedef').AddCallback} AddCallback
 * @typedef {import('../../addons/sharedTypedef').EditCallback} EditCallback
 * @typedef {import('../../addons/sharedTypedef').RemoveCallback} RemoveCallback
 * @typedef {import('../../addons/sharedTypedef').StringCallback} StringCallback

 */

/**
 @typedef {Object} LessonWeb
 @property {String} id Lesson id
 @property {String} data Markdown data
 */

/**
 * @callback GetArrayCallback
 * @param {Error} error
 * @param {Array<LessonWeb>} lessons
 */

/**
 * @callback GetSingleCallback
 * @param {Error} error
 * @param {LessonWeb} lesson
 */

/**
 * Lessons created by the current logged user
 * @param {string} id
 * @param {GetArrayCallback} callback
 */

export function getLessonWebByID(id, callback) {
  axios
    .get(`/api/getLessonWebByID/${id}`, {
      cancelToken: getCancelToken().token
    })
    .then(res => {
      callback(res.data.err, res.data.data);
    })
    .catch(err => {
      if (axios.isCancel(err)) {
      } else {
      }
    });
}

/**
 *
 * @param {LessonWeb} el Lesson to add
 * @param {EditCallback} callback
 */
export function saveLessonWeb(el, callback) {
  axios
    .post(
      "/api/saveLessonWeb",
      { lesson: el },
      { cancelToken: getCancelToken().token }
    )
    .then(res => {
      callback(res.data.err);
    })
    .catch(err => {
      if (axios.isCancel(err)) {
      } else {
      }
    });
}

/**
 * Lessons created by the current logged user
 * @param {Array<FilterInfo>} filters
 * @param {GetArrayCallback} callback
 */

export function getWebVerified(filters, callback) {
  axios
    .get(`/api/getWebVerified/`, {
      cancelToken: getCancelToken().token
    })
    .then(res => {
      callback(res.data.err, res.data.data);
    })
    .catch(err => {
      if (axios.isCancel(err)) {
      } else {
      }
    });
}
