const drawerWidth = 240;
export default theme => ({
  root: {
    display: "flex",
    backgroundColor: theme.palette.text.main,
    position: "absolute",
    top: theme.spacing(8),
    left: 0,
    right: 0,
    bottom: 0
  },
  mobileRoot: {
    backgroundColor: theme.palette.text.main
  },
  appBar: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0
  },
  drawerMobile: {
    width: drawerWidth,
    backgroundColor: "#F5F7FF"
  },
  drawerPaper: {
    width: drawerWidth,
    marginTop: theme.spacing(8),
    backgroundColor: "#F5F7FF"
  },
  toolbar: theme.mixins.toolbar,
  abbBarTxt: {
    paddingLeft: 20,
    textAlign: "left",
    fontSize: 18,
    fontWeight: 500,
    textTransform: "uppercase",
    fontFamily: theme.typography.body1.fontFamily
  },
  content: {
    padding: theme.spacing(3)
  },
  nested: {
    paddingLeft: theme.spacing(4)
  },
  fab: {
    margin: 0,
    bottom: 20,
    right: 20,
    top: "auto",
    left: "auto",
    position: "fixed",
    zIndex: 99999
  },
  extendedIcon: {
    marginRight: theme.spacing(1)
  },
  treeView: {
    width: "100%"
  }
});
