import axios from "axios";

export function getMarkdown(id, callback) {
  axios.get(`/api/getMarkdown/${id}`).then(res => {
    if (res.data.err == null) {
      callback(res.data);
    } else {
      callback("Doesnt exists");
    }
  });
}
