import React, { Component } from "react";
import DynamicTable from "../../../common/dynamicTable/dynamicTable";
import TextField from "@material-ui/core/TextField";
import DialogsInfo from "../../../common/dialogsInfo";
import {
  getData,
  addData,
  editData,
  getSubjectLabel
} from "./courses-controller";
import { imageURL, emptyURL } from "../../../common/imageExits";
import FilterDialog from "../../../common/filter/filterDialog";
import FilterText from "../../../common/filter/filters/filter-text";
import AutoFitImage from "react-image-autofit-frame";
import { getData as getSubjects } from "../subjects/subjects-controller";
import AutoComplete from "../../../common/autocomplete/autocomplete";
import SelectTextfield from "../../../common/autocomplete/select/selectTextfield";

const dialogs = new DialogsInfo();

class Courses extends Component {
  state = {
    imageURL: emptyURL,
    el: { id: "", course: "", imageURL: "" },
    rows: [],
    filterOpen: false,
    loading: false,
    subjects: [],
    subject: null
  };
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.getSubjects();
    this.getData();
  }

  getSubjects = () => {
    getSubjects(null, subjects => {
      this.setState({ subjects });
      if (subjects.length > 0) {
        this.setState({ subject: subjects[0].id });
      }
    });
  };

  onChangeSubject = event => {
    let el = { ...this.state.el };
    el.subjectID = event.target.value;
    el.subject = getSubjectLabel(el.subjectID, this.state.subjects);
    this.setState({ el });
  };

  onElementTextChange = (e, variable) => {
    let el = { ...this.state.el };
    el[variable] = e.target.value;
    if (variable === "imageURL") {
      imageURL(el.imageURL, src => {
        this.setState({ imageURL: src });
      });
    }

    this.setState({ el });
  };

  startAdd = el => {
    let subjectID = 0;
    let subject = "";
    if (this.state.subjects.length > 0) {
      subjectID = this.state.subjects[0].id;
      subject = this.state.subjects[0].subject;
    }

    this.setState(
      { el: { id: "", course: "", imageURL: "", subject, subjectID } },
      () => {
        imageURL(this.state.el.imageURL, src => {
          this.setState({ imageURL: src });
        });
      }
    );
  };

  startEdit = el => {
    this.setState({ el }, () => {
      imageURL(this.state.el.imageURL, src => {
        this.setState({ imageURL: src });
      });
    });
  };

  filterOpen = () => {
    this.setState({ filterOpen: true });
  };

  filterClose = () => {
    this.setState({ filterOpen: false });
  };

  filter = filters => {
    this.getData(filters);
  };

  getData = filters => {
    this.setState({ loading: true });
    getData(filters, rows => {
      this.setState({ rows, loading: false });
    });
  };

  onAdd = () => {
    this.setState({ loading: true });
    addData({ ...this.state.el }, el => {
      this.setState({ rows: [...this.state.rows, el], loading: false });
    });
  };

  onEdit = () => {
    this.setState({ loading: true });
    editData(this.state.el.id, this.state.el, el => {
      let rows = [...this.state.rows];
      rows.forEach(i => {
        if (i.id === el.id) {
          i.subject = el.subject;
          i.subjectID = el.subjectID;
          i.course = el.course;
          i.imageURL = el.imageURL;
        }
      });
      this.setState({ rows, loading: false });
    });
  };

  render() {
    return (
      <React.Fragment>
        <DynamicTable
          title="Course"
          header={["Course", "Subject", "Image URL"]}
          loading={this.state.loading}
          rows={this.state.rows}
          model={["course", "subject", "imageURL"]}
          startAdd={this.startAdd}
          startEdit={this.startEdit}
          startDelete={this.startDelete}
          addDialog={{
            dialog: dialogs.addDialog("course"),
            dialogForm: this.dialogForm,
            action: this.onAdd
          }}
          editDialog={{
            dialog: dialogs.editDialog("course"),
            dialogForm: this.dialogForm,
            action: this.onEdit
          }}
          deleteDialog={{
            dialog: dialogs.deleteDialog("course"),
            dialogForm: this.deleteDialogForm,
            action: this.onDelete
          }}
          filterOpen={this.filterOpen}
        />

        <FilterDialog
          refs={this.state.refs}
          handleClose={this.filterClose}
          open={this.state.filterOpen}
          filter={this.filter}
        >
          <AutoComplete
            label={"Subject"}
            suggestions={this.state.subjects}
            name={"Subject"}
            variables={{ id: "id", label: "subject" }}
            onChange={this.onChange}
          />
          <FilterText label="Course" ref={this.subject} id={"course"} />
        </FilterDialog>
      </React.Fragment>
    );
  }

  dialogForm = () => {
    return (
      <React.Fragment>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Course name"
          type="text"
          autoComplete="off"
          onChange={e => this.onElementTextChange(e, "course")}
          value={this.state.el.course}
          fullWidth
          variant="outlined"
        />

        <SelectTextfield
          margin="dense"
          label="Subject"
          suggestions={this.state.subjects}
          value={this.state.el.subjectID}
          variables={{ id: "id", label: "subject" }}
          onChange={this.onChangeSubject}
        />

        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Image URL"
          type="text"
          autoComplete="off"
          onChange={e => this.onElementTextChange(e, "imageURL")}
          value={this.state.el.imageURL}
          fullWidth
          variant="outlined"
        />
        <div style={{ height: 20 }} />
        <AutoFitImage
          frameWidth="500px"
          frameHeight="400px"
          imgSrc={this.state.imageURL}
        />
      </React.Fragment>
    );
  };
}

export default Courses;
