import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Typography,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  DialogContentText,
  Button
} from "@material-ui/core";
import { withStyles } from "@material-ui/styles";

class DialogCreator extends Component {
  render() {
    const {
      open,
      dialogInfo,
      dialogContent,
      handleClose,
      handleAction,
      fullWidth = false
    } = this.props;

    return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">{dialogInfo.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dialogInfo.content}
          </DialogContentText>
          {dialogContent()}
        </DialogContent>
        <DialogActions>
          {dialogInfo.buttons.length > 1 && (
            <Button onClick={handleClose} color="primary">
              {dialogInfo.buttons[1]}
            </Button>
          )}
          <Button
            onClick={() => {
              handleClose();
              if (handleAction != null) handleAction();
            }}
            color="primary"
            autoFocus
          >
            {dialogInfo.buttons[0]}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

DialogCreator.propTypes = {
  open: PropTypes.bool.isRequired,
  dialogInfo: PropTypes.object.isRequired,
  dialogContent: PropTypes.func.isRequired,
  handleClose: PropTypes.func,
  handleAction: PropTypes.func,
  fullWidth: PropTypes.bool
};

export default DialogCreator;
