import axios from "axios";

export function getData(filters, callback) {
  var rows = [];
  axios
    .get("/api/getAllLessons", {
      params: { filters: filters }
    })
    .then(res => {
      if (res.data.err == null) {
        res.data.data.forEach(el => {
          rows.push({
            id: el.id,
            course: el.course,
            courseID: el.courseID,
            lesson: el.lesson,
            imageURL: el.imageURL,
            type: el.type,
            scene: el.scene,
            requiredPoints: el.requiredPoints,
            completionPoints: el.completionPoints
          });
        });
        callback(rows);
      }
    });
}

export function addData(el, callback) {
  axios.post("/api/addLesson", { lesson: el }).then(res => {
    callback({
      id: res.data,
      lesson: el.lesson,
      imageURL: el.imageURL,
      course: el.course,
      courseID: el.courseID,
      scene: el.scene,
      type: el.type,
      requiredPoints: el.requiredPoints,
      completionPoints: el.completionPoints
    });
  });
}

export function editData(id, el, callback) {
  axios.put(`/api/editLesson/${id}`, { lesson: el }).then(res => {
    callback({
      id: el.id,
      lesson: el.lesson,
      imageURL: el.imageURL,
      course: el.course,
      courseID: el.courseID,
      scene: el.scene,
      type: el.type,
      requiredPoints: el.requiredPoints,
      completionPoints: el.completionPoints
    });
  });
}

export function getCourseLabel(id, courses, callback) {
  var elementPos = courses.findIndex(el => {
    return el.id === id;
  });
  return courses[elementPos].course;
}
