import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import DynamicTable from "../../../common/dynamicTable/dynamicTable";
import TextField from "@material-ui/core/TextField";

import DialogsInfo from "../../../common/dialogsInfo";
import { getData, addData, editData } from "./subjects-controller";
import { imageURL, emptyURL } from "../../../common/imageExits";

import FilterDialog from "../../../common/filter/filterDialog";
import FilterText from "../../../common/filter/filters/filter-text";

import AutoFitImage from "react-image-autofit-frame";
import styles from "./subjects-styles";

const dialogs = new DialogsInfo();

class Subjects extends Component {
  state = {
    imageURL: emptyURL,
    el: { id: "", subject: "", imageURL: "" },
    rows: [],
    filterOpen: false,
    loading: false
  };

  constructor(props) {
    super(props);
    const refs = [];
    this.subject = React.createRef();
    refs.push(this.subject);
    this.state.refs = refs;
  }

  componentDidMount() {
    this.getData();
  }

  onElementTextChange = (e, variable) => {
    let el = { ...this.state.el };
    el[variable] = e.target.value;
    if (variable === "imageURL") {
      imageURL(el.imageURL, src => {
        this.setState({ imageURL: src });
      });
    }

    this.setState({ el });
  };

  startAdd = el => {
    this.setState({ el: { id: "", subject: "", imageURL: "" } }, () => {
      imageURL(this.state.el.imageURL, src => {
        this.setState({ imageURL: src });
      });
    });
  };
  startEdit = el => {
    this.setState({ el }, () => {
      imageURL(this.state.el.imageURL, src => {
        this.setState({ imageURL: src });
      });
    });
  };
  startDelete = el => {
    this.setState({ el });
  };

  filterOpen = () => {
    this.setState({ filterOpen: true });
  };

  filterClose = () => {
    this.setState({ filterOpen: false });
  };

  filter = filters => {
    this.getData(filters);
  };

  dialogForm = () => {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Subject name"
          type="text"
          autoComplete="off"
          onChange={e => this.onElementTextChange(e, "subject")}
          value={this.state.el.subject}
          fullWidth
          variant="outlined"
        />

        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Image URL"
          type="text"
          autoComplete="off"
          onChange={e => this.onElementTextChange(e, "imageURL")}
          value={this.state.el.imageURL}
          fullWidth
          variant="outlined"
        />
        <div style={{ height: 20 }} />
        <AutoFitImage
          frameWidth="500px"
          frameHeight="400px"
          imgSrc={this.state.imageURL}
        />
      </React.Fragment>
    );
  };

  deleteDialogForm = () => {
    return (
      <span>Are you sure you want to delete: {this.state.el.subject}</span>
    );
  };

  getData = filters => {
    this.setState({ loading: true });
    getData(filters, rows => {
      this.setState({ rows, loading: false });
    });
  };

  onAdd = () => {
    this.setState({ loading: true });
    addData(this.state.el, el => {
      this.setState({ rows: [...this.state.rows, el], loading: false });
    });
  };

  onEdit = () => {
    this.setState({ loading: true });
    editData(this.state.el.id, this.state.el, el => {
      let rows = [...this.state.rows];
      rows.forEach(i => {
        if (i.id === el.id) {
          i.subject = el.subject;
          i.imageURL = el.imageURL;
        }
      });
      this.setState({ rows, loading: false });
    });
  };

  onDelete = () => {};

  render() {
    return (
      <React.Fragment>
        <DynamicTable
          title="Subject"
          header={["Identifier", "Subject", "Image URL"]}
          loading={this.state.loading}
          rows={this.state.rows}
          model={["id", "subject", "imageURL"]}
          startAdd={this.startAdd}
          startEdit={this.startEdit}
          startDelete={this.startDelete}
          addDialog={{
            dialog: dialogs.addDialog("subject"),
            dialogForm: this.dialogForm,
            action: this.onAdd
          }}
          editDialog={{
            dialog: dialogs.editDialog("subject"),
            dialogForm: this.dialogForm,
            action: this.onEdit
          }}
          deleteDialog={{
            dialog: dialogs.deleteDialog("subject"),
            dialogForm: this.deleteDialogForm,
            action: this.onDelete
          }}
          filterOpen={this.filterOpen}
        />

        <FilterDialog
          refs={this.state.refs}
          handleClose={this.filterClose}
          open={this.state.filterOpen}
          filter={this.filter}
        >
          <FilterText label="Subject" ref={this.subject} id={"subject"} />
        </FilterDialog>
      </React.Fragment>
    );
  }
}

Subjects.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Subjects);
