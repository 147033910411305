import { createMuiTheme } from "@material-ui/core/styles";
import { dark } from "@material-ui/core/styles/createPalette";

export const darkTheme = createMuiTheme({
  palette: {
    type: "dark",
    primary: {
      light: "#74f6e8",
      main: "#68ddd0",
      dark: "#51aca2"
      //contrastText: "#fff"
    },
    secondary: {
      light: "#7E8CE0",
      main: "#f50057",
      dark: "#c51162",
      contrastText: "#fff"
    },
    background: {
      paper: "#313D4C",
      default: "#27313c"
    },
    text: {
      default: "#ffffff",
      secondary: "#cccccc",
      dark: "#cccccc"
    }

    // error: will use the default color
  },
  typography: {
    useNextVariants: true,
    //fontFamily: ["Rubik", "Montserrat", "Open Sans", "Fira Sans", "Roboto"],
    fontSize: 14,
    h1: { fontFamily: ["Josefin Sans"], fontWeight: 500 },
    h2: { fontFamily: ["Josefin Sans"], fontWeight: 400 },
    h3: { fontFamily: ["Josefin Sans"] },
    h4: { fontFamily: ["Josefin Sans"] },
    h5: { fontFamily: ["Josefin Sans"] },
    h6: { fontFamily: ["Josefin Sans"] },
    body1: {
      fontWeight: 400,
      fontSize: 16,
      fontFamily: ["Rubik"]
    },
    body2: { fontWeight: 400, fontSize: 16, fontFamily: ["Rubik"] },
    button: {
      fontWeight: 400,
      fontFamily: ["Rubik"]
    }
  }
});
