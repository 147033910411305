import React, { Component } from "react";
import PropTypes from "prop-types";
import Avatar from "@material-ui/core/Avatar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import GoogleButton from "react-google-button";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import firebase from "firebase";
import history from "../common/history";
import AuthMngr from "./authmngr";
import CircularProgress from "@material-ui/core/CircularProgress";
import SignedRoute from "../common/signedRoute";
import { AppBar, FormControl, TextField, Snackbar } from "@material-ui/core";
import MainContainer from "../about/container/container";
import MyTextField from "../common/overrides/myTextfield";
import { withStyles } from "@material-ui/core/styles";
import DialogCreator from "../common/dialog/dialogCreator";
import DialogsInfo from "../common/dialogsInfo";
import { isMobile } from "mobile-device-detect";

const dialogs = new DialogsInfo();

const styles = theme => ({
  background: {
    backgroundColor: theme.palette.background.dark,
    backgroundImage: `url("https://images.unsplash.com/photo-1498243691581-b145c3f54a5a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1650&q=80")`,
    backgroundSize: "cover",
    backgroundPosition: "center center",
    position: "fixed",
    top: -10,
    left: -10,
    right: -10,
    bottom: -10,
    filter: "blur(9px)"
  },
  content: {
    backgroundSize: "cover",
    backgroundPosition: "center center",
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0
  },
  main: {
    width: "auto",
    display: "block", // Fix IE 11 issue.
    [theme.breakpoints.up(400 + theme.spacing(3 * 2))]: {
      width: 400,
      marginLeft: "auto",
      marginRight: "auto"
    },

    margin: 0,
    marginTop:100,
    position: "absolute",
    left: "50%",
    transform: "translateX(-50%)"
  },
  mainMobile: {
    display: "block", // Fix IE 11 issue.
margin:20,
    marginTop:54+20,

    position: "absolute",
width:"100%"-20,
height:"100%"-20

  },
  paper: {
    borderRadius: 15,
    //marginTop: theme.spacing.unit * 8,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",

    left: "50%",
    backgroundColor: theme.palette.background.darker,
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`
  },
  avatar: {
    marginBottom: 20,
    padding: 25,
    left: "50%",
    transform: "translate(-50%, 0)",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.background.primary
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1)
    /*    backgroundColor: theme.palette.secondary.main,
    background: "linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)",
    borderRadius: 20,
    border: 0
  */
  },
  signWithGoogle: {
    marginTop: theme.spacing(3),
    border: 0,
    justifyContent: "center"
  },
  typoSignin: {
    color: theme.palette.secondary.font
  },
  progress: {
    marginLeft: 15,
    margin: theme.spacing(1),
    backgroundColor: "red"
  },
  divider: {
    marginTop: 10,
    backgroundColor: theme.palette.background.light
  },
  googleButton: {
    borderRadius: 50
  },
  appBar: {
    position: "relative",
    backgroundColor: theme.palette.background.dark + "00",
    boxShadow: "none",
    color: theme.palette.text.main
  },
  font: {
    color: theme.palette.text.main
  },
  signUpText: {
    color: theme.palette.text.secondary,
    textTransform: "uppercase",
    fontSize: 13
  },
  errorText: {
    color: "red",
    textTransform: "uppercase",
    fontSize: 12
  }
});

class Login extends Component {
  state = {
    callbackid: null,
    loading: false,
    password: "",
    email: "",
    showErrorMessage: false,
    errorText: "",
    dialogOpen: false,
    notificationOpen: false,
    notificationMessage: ""
  };

  constructor(props) {
    super(props);
  }
  /**
   * Check if user is logged and get sign in redirect result
   */
  componentDidMount() {
    this.checkIfUserIsLogged();
  }

  /**
   * Checks manually if the user is logged
   */
  checkIfUserIsLogged() {
    this.sendToCorrectPage();
  }

  sendToCorrectPage() {
    // const authMngr = AuthMngr.getInstance();

    if (firebase.auth().currentUser != null) {
      this.sendToPage("public");
    }

    /*authMngr.getMainRole(role => {
      if (role != null) {
        this.sendToPage(role.role);
      } else {
      }
    });*/
  }

  signInWithEmail = () => {
    this.setState({ loading: true });
    firebase
      .auth()
      .signInWithEmailAndPassword(this.state.email, this.state.password)
      .then(result => {
        if (result.user != null) {
          //alert(result.user.emailVerified);
          AuthMngr.getInstance().signIn(result.user, res => {
            if (!res.data.err) this.sendToCorrectPage();
            else this.setState({ loading: false });
          });
        } else {
          this.setState({ loading: false });
        }
      })
      .catch(err => {
        this.setState({ loading: false });
        this.setState({ showErrorMessage: true, errorText: err.message });
      });
  };

  /**
   * Firebase login
   */
  handleAuth() {
    const provider = new firebase.auth.GoogleAuthProvider();
    this.setState({ loading: true });
    firebase
      .auth()
      .signInWithPopup(provider)
      .then(result => {
        AuthMngr.getInstance().init = false;
        if (result.user != null) {
          //this.sendToCorrectPage();

          AuthMngr.getInstance().signIn(result.user, res => {
            if (!res.data.err) this.sendToCorrectPage();
            else this.setState({ loading: false });
          });
        } else {
          this.setState({ loading: false });
        }
      })
      .catch(err => {
        this.setState({ loading: false });
      });
  }

  /**
   *
   * Depending on whats the role of the logged user, the webpage will
   * redirect the user to the correct page
   * @param {string} role The role of the logged user
   */
  sendToPage = role => {
    if (role === "admin" || role === "system") {
      history.push("/admin/home");
    } else if (role === "public") {
      history.push("/home");
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <CssBaseline />

        <React.Fragment>
          <div className={classes.background}></div>
          <div className={classes.content}>
            <main className={isMobile ? classes.mainMobile : classes.main}>
              {!isMobile&&(
              <Avatar className={classes.avatar}>
                <LockOutlinedIcon />
              </Avatar>)}
              <Paper className={classes.paper}>
                <Typography variant="h5" className={classes.typoSignin}>
                  Iniciar Sesión
                </Typography>

                <form className={classes.form} id="login">
                  <FormControl margin="normal" required fullWidth>
                    <TextField
                      id="email"
                      name="email"
                      label="Email"
                      className={classes.textField}
                      required
                      variant="outlined"
                      autoComplete="username"
                      autoFocus
                      value={this.state.email}
                      onChange={e => {
                        this.setState({ email: e.target.value });
                      }}
                    />
                  </FormControl>
                  <FormControl margin="normal" required fullWidth>
                    <TextField
                      id="password"
                      name="password"
                      label="Contraseña"
                      type="password"
                      className={classes.textField}
                      required
                      variant="outlined"
                      autoComplete="current-password"
                      value={this.state.password}
                      onChange={e => {
                        this.setState({ password: e.target.value });
                      }}
                    />
                  </FormControl>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    disabled={this.state.loading}
                    className={classes.submit}
                    onClick={this.signInWithEmail}
                  >
                    Iniciar Sesión
                  </Button>
                  {this.state.showErrorMessage && (
                    <React.Fragment>
                      <Typography
                        variant={"body2"}
                        className={classes.errorText}
                      >
                        {this.state.errorText}
                      </Typography>
                    </React.Fragment>
                  )}
                  <Button onClick={() => this.setState({ dialogOpen: true })}>
                    Olvide mi contraseña
                  </Button>

                  <Divider className={classes.divider} variant="middle" />
                  <Grid
                    container
                    className={classes.signWithGoogle}
                    alignContent="center"
                  >
                    {/*<GoogleButton
                      disabled={this.state.loading}
                      type="light"
                      classes={classes.googleButton}
                      onClick={() => {
                        this.handleAuth();
                      }}
                    />*/}
                  </Grid>
                  <br />
                  <span className={classes.signUpText}>
                    No tienes una cuenta?
                  </span>

                  <Button onClick={() => history.push("/signup")}>
                    Registrarse
                  </Button>
                </form>
              </Paper>
            </main>
          </div>
        </React.Fragment>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right"
          }}
          open={this.state.notificationOpen}
          autoHideDuration={2000}
          onClose={() => this.setState({ notificationOpen: false })}
          message={this.state.notificationMessage}
        />
        <DialogCreator
          open={this.state.dialogOpen}
          handleClose={() => this.setState({ dialogOpen: false })}
          handleAction={() => {
            AuthMngr.getInstance().forgotPassword(this.state.email, err => {
              this.setState({
                dialogOpen: false,
                notificationMessage: err ? err.message : "Recuperación enviada",
                notificationOpen: true
              });
            });
          }}
          dialogInfo={dialogs.reestablecerPass("Reestablecer contraseña")}
          dialogContent={() => (
            <React.Fragment>
              <TextField
                id="email"
                name="email"
                label="Email"
                required
                variant="outlined"
                autoComplete="username"
                autoFocus
                value={this.state.email}
                onChange={e => {
                  this.setState({ email: e.target.value });
                }}
              />
            </React.Fragment>
          )}
        />
      </React.Fragment>
    );
  }
}

Login.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Login);

/*
Photo by Priscilla Du Preez on Unsplash
<a style="background-color:black;color:white;text-decoration:none;padding:4px 6px;font-family:-apple-system, BlinkMacSystemFont, &quot;San Francisco&quot;, &quot;Helvetica Neue&quot;, Helvetica, Ubuntu, Roboto, Noto, &quot;Segoe UI&quot;, Arial, sans-serif;font-size:12px;font-weight:bold;line-height:1.2;display:inline-block;border-radius:3px" href="https://unsplash.com/@priscilladupreez?utm_medium=referral&amp;utm_campaign=photographer-credit&amp;utm_content=creditBadge" target="_blank" rel="noopener noreferrer" title="Download free do whatever you want high-resolution photos from Priscilla Du Preez"><span style="display:inline-block;padding:2px 3px"><svg xmlns="http://www.w3.org/2000/svg" style="height:12px;width:auto;position:relative;vertical-align:middle;top:-2px;fill:white" viewBox="0 0 32 32"><title>unsplash-logo</title><path d="M10 9V0h12v9H10zm12 5h10v18H0V14h10v9h12v-9z"></path></svg></span><span style="display:inline-block;padding:2px 3px">Priscilla Du Preez</span></a>


*/
