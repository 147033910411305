import React, { Component } from "react";
import { withStyles } from "@material-ui/styles";
import lost from "../../img/lost.svg";
import { Typography, Grid, Box, Button } from "@material-ui/core";
import history from "./history";

const styles = theme => ({
  content: {
    backgroundColor: theme.palette.background.paper,
    //backgroundColor: "blue",
    position: "absolute",
    zIndex: 10000,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0
  }
});

class Error404 extends Component {
  state = {};
  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <div className={classes.content}>
          <Box
            style={{
              padding: 0,
              margin: 0,
              justifyContent: "center",
              alignItems: "center",
              display: "flex"
            }}
            height="100%"
          >
            <Typography variant="h1" color="textSecondary">
              404
              <Typography variant="h3" color="textSecondary">
                Página no encontrada
              </Typography>
              <Button
                size="large"
                variant="outlined"
                color="primary"
                onClick={() => {
                  history.push("/home");
                }}
              >
                Inicio
              </Button>
            </Typography>

            <br />
          </Box>
        </div>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(Error404);
