import React from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import BarChartIcon from "@material-ui/icons/BarChart";
import LayersIcon from "@material-ui/icons/Layers";
import AssignmentIcon from "@material-ui/icons/Assignment";
import Typography from "@material-ui/core/Typography";
import SvgIcon from "@material-ui/core/SvgIcon";
import { ReactComponent as SubjectIcon } from "../../../img/001-teacher.svg";
import { ReactComponent as CourseIcon } from "../../../img/002-book.svg";
import { ReactComponent as LessonIcon } from "../../../img/003-edit.svg";
/*
import SubjectIcon from "@material-ui/icons/Dashboard";
import CourseIcon from "@material-ui/icons/LibraryBooks";
import LessonIcon from "@material-ui/icons/Assignment";
*/
import history from "../../common/history";
import { ThemeProvider } from "@material-ui/styles";

export const mainListItems = (
  <div>
    <ListItem
      button
      onClick={() => {
        history.push("/admin/subjects");
      }}
    >
      <ListItemText
        primary={
          <Typography type="body2" style={{ color: "#000" }}>
            Subjects
          </Typography>
        }
      />
    </ListItem>
    <ListItem
      button
      onClick={() => {
        history.push("/admin/courses");
      }}
    >
      <ListItemText
        primary={
          <Typography type="body2" style={{ color: "##000" }}>
            Courses
          </Typography>
        }
      />
    </ListItem>

    <ListItem
      button
      onClick={() => {
        history.push("/admin/classrooms");
      }}
    >
      <ListItemText
        primary={
          <Typography type="body2" style={{ color: "##000" }}>
            Classrooms
          </Typography>
        }
      />
    </ListItem>
  </div>
);

export const secondaryListItems = (
  <div>
    <ListSubheader inset>Saved reports</ListSubheader>
    <ListItem button>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Current month" />
    </ListItem>
    <ListItem button>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Last quarter" />
    </ListItem>
    <ListItem button>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Year-end sale" />
    </ListItem>
  </div>
);
