import axios from "axios";
import { getCancelToken } from "./cancel-axios-token";

/**
 * @typedef {import('../typedefs').FilterInfo} FilterInfo
 * @typedef {import('../../addons/sharedTypedef').AddCallback} AddCallback
 * @typedef {import('../../addons/sharedTypedef').EditCallback} EditCallback
 * @typedef {import('../../addons/sharedTypedef').RemoveCallback} RemoveCallback
 * @typedef {import('../../addons/sharedTypedef').StringCallback} StringCallback

 */

/**
 @typedef {Object} LessonAR
 @property {String} id Lesson id
 @property {String} scene
 @property {number} completionPoints
 @property {number} requiredPoints
 */

/**
@typedef {LessonAllInfo & LessonAR} LessonAllInfoAR
*/

/**
 * @callback GetArrayCallback
 * @param {Error} error
 * @param {Array<LessonAR>} lessons
 */

/**
 * @callback GetLessonAllInfoARArray
 * @param {Error} error
 * @param {Array<LessonAllInfoAR>} lessons
 */

/**
 * @callback GetSingleCallback
 * @param {Error} error
 * @param {LessonAR} lesson
 */

/**
 * Lessons created by the current logged user
 * @param {string} id
 * @param {GetArrayCallback} callback
 */

export function getLessonARByID(id, callback) {
  axios
    .get(`/api/getLessonARByID/${id}`, { cancelToken: getCancelToken().token })
    .then(res => {
      callback(res.data.err, res.data.data);
    })
    .catch(err => {
      if (axios.isCancel(err)) {
      } else {
      }
    });
}

/**
 *
 * @param {LessonAR} el Lesson to add
 * @param {EditCallback} callback
 */
export function saveLessonAR(el, callback) {
  axios
    .post(
      "/api/saveLessonAR",
      { lesson: el },
      { cancelToken: getCancelToken().token }
    )
    .then(res => {
      callback(res.data.err);
    })
    .catch(err => {
      if (axios.isCancel(err)) {
      } else {
      }
    });
}

/**
 * Lessons created by the current logged user
 * @param {Array<FilterInfo>} filters
 * @param {GetArrayCallback} callback
 */

export function getARVerified(filters, callback) {
  axios
    .get(`/api/getARVerified/`, {
      cancelToken: getCancelToken().token
    })
    .then(res => {
      callback(res.data.err, res.data.data);
    })
    .catch(err => {
      if (axios.isCancel(err)) {
      } else {
      }
    });
}
