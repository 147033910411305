import React, { Component } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import styles from "./about-styles";
import MainContainer from "../container/container";
import { isMobile, isAndroid } from "mobile-device-detect";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://material-ui.com/">
        Your Website
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

class About extends Component {
  renderAbout() {
    const classes = this.props.classes;
    return (
      <React.Fragment>
        {" "}
        <Typography component="h1" variant="h3">
          Acerca de
        </Typography>
        <br />
        <br />
        <Typography component="h2" variant="body2">
          <p>
            Babel es un material de apoyo para la enseñanza y aprendizaje
            enfocada en la rama de la física y matemática. Tiene como pilar
            fundamental la interacción del usuario con las distintas lecciones,
            para cumplir este objetivo cuenta con una aplicación móvil que tiene
            la capacidad de visualizar los temas con realidad aumentada.
          </p>
          <p>
            Babel está conformado por un repertorio de temas puntuales de las
            materias de matemática y física, presentando múltiples recursos
            (tanto teóricos como interactivos) para cada uno de estos, así con
            el fin de mejorar la visualización y comprensión del usuario que use
            la aplicación, fomentando a su vez, el interés hacia el sector
            científico en los estudiantes y entusiastas del saber.
          </p>
          <p>
            Creada por: José Guerra y Alexis Orellana En la práctica final de la
            Carrera de Ingeniería en Ciencias y Sistemas del Centro
            Universitario de Oriente CUNORI-USAC Chiquimula, Guatemala
          </p>
        </Typography>
      </React.Fragment>
    );
  }
  render() {
    const classes = this.props.classes;

    return (
      <React.Fragment>
        {isMobile ? (
          <main className={classes.mobileContainer}>{this.renderAbout()}</main>
        ) : (
          <main className={classes.content}>
            <Grid container component="main" className={classes.root}>
              <CssBaseline />
              <Grid item xs={false} sm={4} md={7} className={classes.image} />
              <Grid
                item
                xs={12}
                sm={8}
                md={5}
                component={Paper}
                elevation={6}
                square
              >
                <div className={classes.paper}>{this.renderAbout()}</div>
              </Grid>
            </Grid>
          </main>
        )}
      </React.Fragment>
    );
  }
}

About.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(About);
