import React, { Component } from "react";
import Divider from "@material-ui/core/Divider";
import Container from "@material-ui/core/Container";
import PropTypes from "prop-types";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import ImageIcon from "@material-ui/icons/PersonOutline";
import { Paper } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Skeleton from "@material-ui/lab/Skeleton";

const loadingArray = [
  { id: "0" },
  { id: "1" },
  { id: "2" },
  { id: "3" },
  { id: "4" }
];

const styles = theme => ({
  //////////LEADER BOARD
  rootLeaderboard: {
    padding: theme.spacing(2),
    color: theme.palette.text.main,
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.secondary,
    borderRadius: 0
  },
  avatar: {
    width: 30,
    height: 30
  },
  nameItem: {}
});

class Leaderboard extends Component {
  state = {};
  render() {
    const {
      classes,
      loading = true,
      rows = [],
      imageFieldName = "imageURL",
      nameFieldName = "name",
      pointsFieldName = "points"
    } = this.props;

    const data = loading || rows == null ? loadingArray : rows;
    return (
      <React.Fragment>
        <Paper elevation={0} className={classes.rootLeaderboard}>
          <Typography variant={"h6"}>Miembros</Typography>
          <List>
            {data.map((el, index) => {
              return loading ? (
                <Skeleton height={30} key={el.id} />
              ) : (
                <ListItem key={el.id}>
                  <ListItemAvatar>
                    <Avatar
                      className={classes.avatar}
                      src={el[imageFieldName]}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    className={classes.nameItem}
                    primary={el[nameFieldName]}
                    secondary={el[pointsFieldName]}
                    primaryTypographyProps={{ align: "right" }}
                  />
                </ListItem>
              );
            })}
          </List>
        </Paper>
      </React.Fragment>
    );
  }
}

Leaderboard.propTypes = {
  classes: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  rows: PropTypes.array,
  imageFieldName: PropTypes.string,
  nameFieldName: PropTypes.string,
  pointsFieldName: PropTypes.string
};

export default withStyles(styles)(Leaderboard);
