import React, { Component } from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import styles from "./home-styles";
import history from "../../common/history";
import { isMobile, isAndroid } from "mobile-device-detect";
import DownloadIcon from "@material-ui/icons/ArrowDownward";
import AuthMngr from "../../login/authmngr";

class MainHome extends Component {
  state = {};

  redirectToSignIn = () => {
    history.push("/signin");
  };

  _loginEvent = idToken => {
    if (AuthMngr.getInstance().isSigned) {
      history.push("/home");
    }
  };
  componentDidMount() {
    this._loginEvent = AuthMngr.getInstance().subscribeOrExecute(
      this._loginEvent
    );
  }

  componentWillUnmount() {
    AuthMngr.getInstance().unsubscribeEvent(this._loginEvent);
  }

  handleClose = () => {
    this.setState({ showDownloadQR: false });
  };

  handleOpen = () => {
    this.setState({ showDownloadQR: true });
  };

  render() {
    const classes = this.props.classes;

    return (
      <React.Fragment>
        <div className={classes.background} />
        <div className={classes.heroUnit}>
          <div
            className={
              isMobile ? classes.heroContentMobile : classes.heroContent
            }
          >
            <Typography
              component="h1"
              variant="h2"
              align="center"
              className={classes.textTitle}
              gutterBottom
            >
              Babel
            </Typography>
            <Typography
              variant="body1"
              align="center"
              className={classes.textDescription}
              paragraph
            >
              Babel es una plataforma que facilita el aprendizaje mediante el
              uso de realidad aumentada, dando una experiencia mas interactiva y
              entretenida
            </Typography>
            <div className={classes.heroButtons}>
              <Grid container spacing={2} justify="center">
                <Grid item>
                  <Button
                    variant="contained"
                    size={"large"}
                    className={classes.primaryButton}
                    onClick={() => {
                      history.push("/downloads");
                    }}
                  >
                    <DownloadIcon className={classes.extendedIcon} />
                    Descargar
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="outlined"
                    size={"large"}
                    className={classes.secondaryButton}
                    onClick={() => history.push("/signUp")}
                  >
                    Crear Cuenta
                  </Button>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

MainHome.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(MainHome);
