export default theme => ({
  content: {
    marginTop: 0,
    flexGrow: 1,
    padding: theme.spacing(3),
    paddingTop: 100,
    height: "100vh",
    overflow: "auto"
  }
});
