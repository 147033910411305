import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import SignedRoute from "../../common/signedRoute";
import { CssBaseline, Paper, AppBar, Toolbar } from "@material-ui/core";
import Mylessons from "../lessonCreator/lessonManager";
import CreateClassroom from "../classrooms/create/createClassroom";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}

const styles = theme => ({
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`
  },
  editor: {
    textAlign: "left",
    padding: 0
  },
  content: {
    padding: theme.spacing(0)
  },
  appBar: {
    backgroundColor: theme.palette.background.paper,
    //backgroundColor: "red",
    color: theme.palette.text.primary
  },
  tabPanel: {
    width: "100%",
    padding: 0,
    margin: 0
  },
  grow: {
    flexGrow: 1
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  iconButton: {
    marginLeft: theme.spacing(1)
  }
});

class Management extends Component {
  state = {
    value: 0
  };

  start = () => {};

  handleChange = (event, newValue) => {
    this.setState({ value: newValue });
  };

  render() {
    const { classes } = this.props;
    const { value, loading } = this.state;

    return (
      <SignedRoute role="public" executeOnValid={this.start}>
        <CssBaseline />
        <main className={classes.content}>
          <Paper square elevation={0}>
            <AppBar position="static" className={classes.appBar} elevation={0}>
              <Toolbar>
                <Tabs
                  value={value}
                  onChange={this.handleChange}
                  aria-label="tabs"
                >
                  <Tab label="Lecciones" />
                  <Tab label="Salones" />
                </Tabs>

                <div className={classes.grow} />
              </Toolbar>
            </AppBar>
          </Paper>
          <TabPanel value={value} index={0} className={classes.tabPanel}>
            <Mylessons />
          </TabPanel>
          <TabPanel value={value} index={1} className={classes.tabPanel}>
            <CreateClassroom />
          </TabPanel>
        </main>
      </SignedRoute>
    );
  }
}

export default withStyles(styles)(Management);
