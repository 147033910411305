import React, { Component } from "react";
import TextField from "@material-ui/core/TextField";
import FilterItem from "./filterItem/filterItem";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";

class FilterText extends Component {
  state = {
    searchVal: ""
  };

  constructor(props) {
    super(props);
    this.filterItem = React.createRef();
  }

  getFilter = () => {
    let filter = { type: "text", id: this.props.id, val: this.state.searchVal };
    return this.filterItem.current.isEnabled() ? filter : null;
  };

  onChange = event => {
    this.setState({ searchVal: event.target.value });
  };

  render() {
    return (
      <FilterItem innerRef={this.filterItem}>
        <TextField
          id="standard-uncontrolled"
          label={this.props.label}
          onChange={this.onTypeEvent}
          value={this.state.searchVal}
          variant="outlined"
          type="text"
          autoComplete="off"
          fullWidth
        />
      </FilterItem>
    );
  }
}

export default FilterText;
