import React, { Component } from "react";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  textField: {
    borderColor: theme.palette.secondary.font
  },
  input: {
    color: theme.palette.secondary.font
  },
  cssLabel: {
    "&$cssFocused": {
      color: theme.palette.primary.font
    },
    color: theme.palette.secondary.font
  },
  cssFocused: {},
  cssOutlinedInput: {
    "&$cssFocused $notchedOutline": {
      borderColor: `${theme.palette.primary.font} !important`
    }
  },
  notchedOutline: {
    borderColor: `${theme.palette.secondary.font} !important`
  }
});

class MyTextfield extends Component {
  state = {};
  render() {
    const { classes } = this.props;
    return (
      <TextField
        id={this.props.id}
        name={this.props.name}
        label={this.props.label}
        type={this.props.type}
        className={classes.textField}
        margin={this.props.margin}
        variant={this.props.variant}
        autoComplete={this.props.autoComplete}
        InputLabelProps={{
          classes: {
            root: classes.cssLabel,
            focused: classes.cssFocused
          }
        }}
        InputProps={{
          classes: {
            root: classes.cssOutlinedInput,
            focused: classes.cssFocused,
            notchedOutline: classes.notchedOutline,
            input: classes.input
          }
        }}
        autoFocus={this.props.autoFocus}
      />
    );
  }
}

MyTextfield.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(MyTextfield);
