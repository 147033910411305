import axios from "axios";

import { getCancelToken } from "./cancel-axios-token";
/**
 * @typedef {import('../typedefs').FilterInfo} FilterInfo
 * @typedef {import('../../addons/sharedTypedef').AddCallback} AddCallback
 * @typedef {import('../../addons/sharedTypedef').EditCallback} EditCallback
 * @typedef {import('../../addons/sharedTypedef').RemoveCallback} RemoveCallback
 * @typedef {import('../../addons/sharedTypedef').StringCallback} StringCallback

 */

/**
 @typedef {Object} Lesson
 @property {String} id Lesson id
 @property {String} lesson Lesson name
 @property {String} courseID 
 @property {String} subjectID
 @property {String} imageURL Thumbnail of the lesson
 @property {String} translation Translated name
 */

/**
 @typedef {Object} _LessonAllInfo
 @property {String} course Course name
 @property {String} subject Subject name
 @property {String} courseTranslation Course translation
 @property {String} subjectTranslation Subject translation
 @typedef {Lesson & _LessonAllInfo} LessonAllInfo 
 */

/**
 * @callback GetArrayCallback
 * @param {Error} error
 * @param {Array<LessonAllInfo>} lessons
 */

/**
 * @callback GetSingleCallback
 * @param {Error} error
 * @param {LessonAllInfo} lesson
 */

/**
 * Lessons created by the current logged user
 * @param {FilterInfo} filters
 * @param {GetArrayCallback} callback
 */

export function getMyLessons(filters, callback) {
  axios
    .get("/api/getMyLessons", {
      params: { filters: filters },
      cancelToken: getCancelToken().token
    })
    .then(res => {
      callback(res.data.err, res.data.data);
    })
    .catch(err => {
      if (axios.isCancel(err)) {
      } else {
      }
    });
}

/**
 *
 * @param {Lesson} el Lesson to add
 * @param {GetSingleCallback} callback
 */
export function addData(el, callback) {
  axios
    .post(
      "/api/addLesson",
      { lesson: el },
      { cancelToken: getCancelToken().token }
    )
    .then(res => {
      const newElement = { ...el };
      newElement.id = res.data.data;
      callback(res.data.err, newElement);
    })
    .catch(err => {
      if (axios.isCancel(err)) {
      } else {
      }
    });
}

/**
 *
 * @param {string} id
 * @param {Lesson} el
 * @param {GetSingleCallback} callback
 */
export function editData(id, el, callback) {
  axios
    .put(
      `/api/editLesson/${id}`,
      { lesson: el },
      { cancelToken: getCancelToken().token }
    )
    .then(res => {
      callback(res.data.err, el);
    })
    .catch(err => {
      if (axios.isCancel(err)) {
      } else {
      }
    });
}

/**
 * Finds the label of the course with its id
 * @param {string} id courseID
 * @param {Array} courses Array of courses
 * @returns {string} course name
 */
export function getCourseLabel(id, courses) {
  var elementPos = courses.findIndex(el => {
    return el.id === id;
  });
  return courses[elementPos].course;
}
