import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/Add";
import styles from "./dynamicTable-style";
import Button from "@material-ui/core/Button";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import LinearProgress from "@material-ui/core/LinearProgress";
import EnhancedTableToolbar from "./enhancedTableToolbar";

/**
 @typedef {Object} DialogInfo
 @property {String} title Title of the dialog
 @property {String} content Text of the dialog
 @property {String} buttons Name of the buttons ["OK","Cancel"] 
 @property {function} action Action of the first button
 */

class DynamicTable extends Component {
  state = {
    dialogOpen: false,
    dialog: { title: "", content: "", buttons: ["Ok", "Cancel"], action: null },
    dialogForm: this.dialogForm(),
    dialogToUse: "addDialog",
    rowsPerPage: 5,
    page: 0,
    extraButtons: []
  };

  constructor(props) {
    super(props);
    this.startAdd = this.startAdd.bind(this);
    this.startEdit = this.startEdit.bind(this);
    this.startDelete = this.startDelete.bind(this);
    this.closeDialog = this.closeDialog.bind(this);
    this.setupDialog = this.setupDialog.bind(this);
    this.startDialog = this.startDialog.bind(this);
  }

  /**
   *
   * Opens a dialog with dynamic parameters that can be editing by sending props
   *
   * @param {function(Object)} start functions that executes before opening the dialog
   * @param {DialogInfo} dialog dialog object that contains dialog info, dialog form, and action
   * @param {Object} el element that im editing or deliting (null for adding)
   */
  startDialog(start, dialog, el) {
    if (start) start(el);
    if (dialog)
      this.setupDialog(dialog.dialog, dialog.dialogForm(el), dialog.action);
    this.setState({ dialogOpen: true });
  }

  /**
   * Opens the correct dialog
   */
  startAdd() {
    this.setState({ dialogToUse: "addDialog" });
    this.startDialog(this.props.startAdd, this.props.addDialog, null);
  }

  /**
   * Opens the correct dialog and set the values
   * @param {Object} el Element to edit
   */
  startEdit(el) {
    this.setState({ dialogToUse: "editDialog" });
    this.startDialog(this.props.startEdit, this.props.editDialog, el);
  }

  /**
   * Opens the correct dialog and set the values
   * @param {Object} el Element to delete
   */

  startDelete(el) {
    this.setState({ dialogToUse: "deleteDialog" });
    this.startDialog(this.props.startDelete, this.props.deleteDialog, el);
  }

  /**
   * Closes the dialog
   */
  closeDialog() {
    this.setState({ dialogOpen: false });
  }

  /**
   * Changes the dialog content to fit it with the desired parameters
   *
   * @param {DialogInfo} dialog Object type dialog{title,content,buttons}
   * @param {function(void):Component} dialogForm Function that returns a Form
   * @param {function(void)} action Action to make when Ok is pressed
   */
  setupDialog(dialog, dialogForm, action) {
    this.setState({ dialog });

    if (dialogForm != null) {
      this.setState({ dialogForm: dialogForm });
    } else {
      this.setState({ dialogForm: this.dialogForm });
    }

    this.setState({ action });
  }

  /**
   * Empty dialog
   */
  dialogForm() {
    return <React.Fragment />;
  }

  /*
  handleChangePage = (event, page) => {
    this.setState({ page });
    if (this.props.handleChangePage) this.props.handleChangePage();
  };
  


  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
    if (this.props.handleChangeRowsPerPage)
      this.props.handleChangeRowsPerPage();
  };
*/

  render() {
    const { classes, header, rows, model, title } = this.props;

    return (
      <React.Fragment>
        <Paper className={classes.root}>
          <div className={classes.tableWrapper}>
            {this.props.loading && (
              <LinearProgress
                classes={{
                  colorPrimary: classes.linearColorPrimary,
                  barColorPrimary: classes.linearBarColorPrimary
                }}
              />
            )}
            <EnhancedTableToolbar
              title={title}
              filterOpen={this.props.filterOpen}
            />

            <Table className={classes.table}>
              <TableHead>
                <TableRow key={-1} className={classes.head}>
                  {header.map((el, index) => (
                    <TableCell key={index} className={classes.tableCell}>
                      {el}
                    </TableCell>
                  ))}
                  {this.props.addDialog && (
                    <TableCell className={classes.tableCell} align="right">
                      <IconButton
                        className={classes.buttonAdd}
                        aria-label="Add"
                        onClick={this.startAdd}
                      >
                        <AddIcon />
                      </IconButton>
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>

              <TableBody>{this.renderRows(classes, rows, model)}</TableBody>
            </Table>
          </div>
        </Paper>

        {(this.props.addDialog || this.props.editDialog) && (
          <Dialog
            open={this.state.dialogOpen}
            onClose={this.closeDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {this.state.dialog.title}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {this.state.dialog.content}
              </DialogContentText>
              {this.props[this.state.dialogToUse].dialogForm()}
            </DialogContent>
            <DialogActions>
              {this.state.dialog.buttons.length > 1 && (
                <Button onClick={this.closeDialog} color="primary">
                  {this.state.dialog.buttons[1]}
                </Button>
              )}
              <Button
                onClick={() => {
                  this.closeDialog();
                  if (this.state.action != null) this.state.action();
                }}
                color="primary"
                autoFocus
              >
                {this.state.dialog.buttons[0]}
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </React.Fragment>
    );
  }

  renderEmptyRow = (classes, model) => {
    return (
      <TableRow key={0} className={classes.tablePagination}>
        {(this.props.editDialog || this.props.deleteDialog) && (
          <TableCell key={-1} className={classes.tableCell}>
            {this.props.loading ? "" : "No data found"}
          </TableCell>
        )}
        {model.map((el, index2) => (
          <TableCell key={index2} className={classes.tableCell}>
            {index2 == 0 &&
              (!this.props.editDialog && !this.props.deleteDialog) && (
                <React.Fragment>
                  {this.props.loading ? "" : "No data found"}
                </React.Fragment>
              )}
          </TableCell>
        ))}
      </TableRow>
    );
  };

  renderExtras(el) {
    if (this.props.extras) {
      return this.props.extras(el);
    }
    return <React.Fragment></React.Fragment>;
  }

  renderRows(classes, rows, model) {
    if (rows.length <= 0) {
      return this.renderEmptyRow(classes, model);
    }

    return (
      <React.Fragment>
        {rows.map((row, index) => (
          <TableRow key={index} className={classes.tableRow}>
            {model.map((el, index2) => (
              <TableCell
                key={index2}
                className={classes.tableCell}
                component="th"
                scope="row"
              >
                {truncString(row[el], 30, "...")}
              </TableCell>
            ))}
            {(this.props.deleteDialog ||
              this.props.editDialog ||
              this.props.extras) && (
              <TableCell className={classes.tableCell} align="right">
                {this.renderExtras(row)}
                {this.props.editDialog && (
                  <IconButton
                    className={classes.buttonEdit}
                    aria-label="Edit"
                    onClick={() => {
                      this.startEdit(row);
                    }}
                  >
                    {!this.props.editIconOverride ? (
                      <EditIcon />
                    ) : (
                      this.props.editIconOverride()
                    )}
                  </IconButton>
                )}
                {this.props.deleteDialog && (
                  <IconButton
                    className={classes.buttonDelete}
                    aria-label="Delete"
                    onClick={() => {
                      this.startDelete(row);
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                )}
              </TableCell>
            )}
          </TableRow>
        ))}
      </React.Fragment>
    );
  }
}
function truncString(str, max, add) {
  add = add || "...";
  return typeof str === "string" && str.length > max
    ? str.substring(0, max) + add
    : str;
}
DynamicTable.propTypes = {
  classes: PropTypes.object.isRequired,
  /**
   * Header names
   */
  header: PropTypes.array.isRequired,
  /**
   * All the data
   */
  rows: PropTypes.array.isRequired,
  /**
   * Rows names (usually the field name in db)
   * this have to match with the header names
   */

  model: PropTypes.array.isRequired,

  /**
   * Name of the table
   */
  title: PropTypes.string,

  /**
   * Extra components in the table,
   * is a function that creates all the extras
   * it recieves an element as a parameter
   */
  extras: PropTypes.func
};

export default withStyles(styles)(DynamicTable);
