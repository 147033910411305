import React, { Component } from "react";
import LessonCreator from "./lessonCreator";
import Mylessons from "../myLessons/mylessons";
import { ArrowBackTwoTone } from "@material-ui/icons";
import {
  Typography,
  IconButton,
  Dialog,
  withStyles,
  DialogTitle,
  DialogActions,
  Button
} from "@material-ui/core";
import DialogCreator from "../../common/dialog/dialogCreator";

const styles = {
  dialogPaper: {
    minHeight: "80vh",
    maxHeight: "80vh",
    minWidth: "75vw",
    maxWidth: "75vw"
  },
  dialogTitle: {
    display: "flex"
  }
};

class LessonManager extends Component {
  state = { editingName: "", editing: false, editingId: "" };
  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <Mylessons
          moreAction={el => {
            this.setState({
              editingName: el.lesson,
              editingId: el.id,
              editing: true
            });
          }}
        />
        <Dialog
          open={this.state.editing}
          classes={{ paper: classes.dialogPaper }}
          onClose={() => {
            this.setState({ editing: false });
          }}
        >
          <DialogTitle className={classes.dialogTitle} disableTypography>
            <Typography variant={"h6"}>{this.state.editingName}</Typography>
            <div style={{ flexGrow: 1 }} />
            <Button onClick={() => this.setState({ editing: false })}>
              Close
            </Button>
          </DialogTitle>
          <LessonCreator
            name={this.state.editingName}
            id={this.state.editingId}
          />
        </Dialog>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(LessonManager);
