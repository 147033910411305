var imageExists = require("image-exists");

export function imageURL(src, callback) {
  imageExists(src, exists => {
    if (exists) {
      callback(src);
    } else {
      callback(emptyURL);
    }
  });
}

export const emptyURL =
  "https://www.cornellstore.com/b2c/img/no_image_available.jpeg?resizeid=5&resizeh=1200&resizew=1200";
