export default theme => ({
  textField: {
    borderColor: theme.palette.secondary.font
  },
  input: {
    color: "#000"
  },
  cssLabel: {
    "&$cssFocused": {
      color: theme.palette.secondary.dark
    },
    color: theme.palette.secondary.main
  },
  cssFocused: {},
  cssOutlinedInput: {
    "&$cssFocused $notchedOutline": {
      borderColor: `${theme.palette.secondary.main} !important`
    }
  },
  notchedOutline: {
    borderColor: `${theme.palette.secondary.main} !important`
  }
});
