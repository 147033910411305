import React, { Component } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import styles from "./contact-styles";
import MainContainer from "../container/container";
import Avatar from "@material-ui/core/Avatar";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import { sendEmail } from "../../../backend-communication/contact-controller";
import { Snackbar } from "@material-ui/core";
import { cancelRequests } from "../../../backend-communication/cancel-axios-token";
import { isMobile, isAndroid } from "mobile-device-detect";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://material-ui.com/">
        Your Website
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

class Contact extends Component {
  state = {
    email: "",
    subject: "",
    message: "",
    loading: false,
    notificationText: "",
    notificationOpen: false
  };
  componentWillUnmount() {
    cancelRequests("unmounting");
  }

  sendEmail = () => {
    this.setState({ loading: true });
    sendEmail(
      this.state.email,
      this.state.subject,
      this.state.message,
      (err, data) => {
        const notificationText =
          err != null ? "Error sending email" : "Email sent successfully";
        this.setState({
          loading: false,
          notificationOpen: true,
          notificationText
        });
      }
    );
  };

  renderForm = () => {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <Typography component="h1" variant="h4">
          Contacto
        </Typography>
        <form className={classes.form} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email"
            name="email"
            autoComplete="email"
            onChange={e => {
              this.setState({ [e.target.name]: e.target.value });
            }}
            autoFocus
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="subject"
            label="Asunto"
            name="subject"
            autoComplete="subject"
            onChange={e => {
              this.setState({ [e.target.name]: e.target.value });
            }}
            autoFocus
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="message"
            label="Mensaje"
            type="message"
            id="message"
            multiline={true}
            autoComplete="current-message"
            value={this.state.message}
            onChange={e => {
              this.setState({ [e.target.name]: e.target.value });
            }}
          />
          <Button
            type="button"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={this.state.loading}
            onClick={this.sendEmail}
          >
            Send
          </Button>

          <br />
          <br />
          <br />
          <Typography component="h6" variant="h6">
            Para
          </Typography>
          <Typography component="body2" variant="body2">
            babelcontact502@gmail.com
          </Typography>
        </form>
      </React.Fragment>
    );
  };

  render() {
    const classes = this.props.classes;

    return (
      <React.Fragment>
        {isMobile ? (
          <React.Fragment>
            <main className={classes.mobileContainer}>{this.renderForm()}</main>
          </React.Fragment>
        ) : (
          <Grid container component="main" className={classes.root}>
            <CssBaseline />
            <Grid item xs={false} sm={4} md={7} className={classes.image} />
            <Grid
              item
              xs={12}
              sm={8}
              md={5}
              component={Paper}
              elevation={6}
              square
            >
              <div className={classes.paper}>{this.renderForm()}</div>
            </Grid>
          </Grid>
        )}

        <Snackbar
          message={this.state.notificationText}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right"
          }}
          open={this.state.notificationOpen}
          onClose={() => {
            this.setState({ notificationOpen: false });
          }}
        />
      </React.Fragment>
    );
  }
}

Contact.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Contact);
