import React, { Component } from "react";
import { withStyles } from "@material-ui/styles";
import {
  Typography,
  Grid,
  Box,
  Button,
  CircularProgress
} from "@material-ui/core";
import history from "../../common/history";
import SignedRoute from "../../common/signedRoute";
import {
  getClassroomById,
  getInvitation,
  belongsToClassroom,
  joinClassroom
} from "../../../backend-communication/classroom-controller";
const queryString = require("query-string");

const styles = theme => ({
  content: {
    backgroundColor: theme.palette.background.paper,
    //backgroundColor: "blue",
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0
  }
});

class JoinClassroomLink extends Component {
  state = {
    classroom: { name: "", imageURL: "" },
    loadingCoverPage: true,
    invitation: ""
  };

  start = () => {
    const query = queryString.parse(this.props.location.search);
    this.setState({ loadingCoverPage: true });

    belongsToClassroom(query.id, val => {
      if (val)
        history.push({ pathname: "classroom", search: `?id=${query.id}` });
      else {
        getInvitation(query.id, (err, data) => {
          if (err || !data) {
            history.push("/404");
          } else {
            this.setState({ invitation: data, loadingCoverPage: false });
          }
        });
      }
    });
  };
  render() {
    const { classes } = this.props;
    return (
      <SignedRoute role="public" executeOnValid={this.start}>
        <div className={classes.content}>
          <Box
            style={{
              padding: 0,
              margin: 0,
              justifyContent: "center",
              alignItems: "center",
              display: "flex"
            }}
            height="100%"
          >
            {this.state.loadingCoverPage ? (
              <CircularProgress />
            ) : (
              <Typography variant="h1" color="textSecondary">
                <Typography variant="h3" color="textSecondary">
                  {this.props.location.state.name}
                </Typography>
                <Button
                  size="large"
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    this.setState({ loadingCoverPage: true });
                    joinClassroom(this.state.invitation, (err, classroomId) => {
                      if (!err)
                        history.push({
                          pathname: "classroom",
                          search: `?id=${classroomId}`
                        });

                      this.setState({ loadingCoverPage: false });
                    });
                  }}
                >
                  Join
                </Button>
              </Typography>
            )}

            <br />
          </Box>
        </div>
      </SignedRoute>
    );
  }
}

export default withStyles(styles)(JoinClassroomLink);
