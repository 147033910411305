export default theme => ({
  containerMobile: {
    maxWidth: "100%"
  },
  container: {
    maxWidth: "80%"
  },
  mainFeaturedPost: {
    marginTop: 40,
    position: "relative",
    backgroundColor: theme.palette.background.dark,
    color: theme.palette.common.white,
    marginBottom: theme.spacing(4),
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    height: 300
  },
  mainFeaturedPostMobile: {
    marginTop: 0,
    position: "relative",
    backgroundColor: theme.palette.background.dark,
    color: theme.palette.common.white,
    marginBottom: theme.spacing(4),
    width: "100%",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    height: 300
  },

  coverPage: {
    position: "absolute",
    top: 0,
    left: 0,
    objectFit: "cover",
    width: "100%",
    height: "100%"
  },
  overlay: {
    position: "absolute",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    backgroundColor: "rgba(0,0,0,0.35)"
  },
  mainFeaturedPostContent: {
    position: "relative",
    height: 300,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  },
  mainGrid: {
    marginTop: theme.spacing(3)
  },
  title: {
    color: theme.palette.text.main
  },
  subtitle: {
    color: theme.palette.text.secondary
  },
  gridList: {
    width: 500,
    height: 450
  },
  button: {
    boxShadow: "none",
    backgroundColor: theme.palette.primary.main + "50",
    color: theme.palette.primary.main,
    "&:hover": { backgroundColor: theme.palette.primary.main + "75" }
  },
  rightIcon: {
    marginLeft: theme.spacing(1)
  },
  actionButton: {
    color: theme.palette.primary.main,
    padding: 5
  },
  actionButtonDelete: {
    color: theme.palette.secondary.main,
    padding: 5
  },
  classroomActions: {
    display: "flex",
    flexDirection: "left"
  },
  appBarWeb: {
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.main
  },
  lessonContent: {
    padding: 50
  },
  toolbarWeb: {
    color: theme.palette.text.main
  }
});
