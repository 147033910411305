import React, { Component } from "react";
import HomeAdmin from "./homeAdmin";
import Classroom from "./classroom/classroom";

class Classrooms extends Component {
  state = {};

  render() {
    return (
      <HomeAdmin>
        <Classroom />
      </HomeAdmin>
    );
  }
}

export default Classrooms;
