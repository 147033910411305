import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import SignedRoute from "../../common/signedRoute";
import {
  Container,
  CssBaseline,
  Paper,
  AppBar,
  IconButton,
  Toolbar,
  Button,
  TextField,
  Grid,
  FormGroup,
  CircularProgress
} from "@material-ui/core";

import { SaveOutlined } from "@material-ui/icons";
import Editor from "react-simplemde-editor";
import "easymde/dist/easymde.min.css";
import {
  saveLessonAR,
  getLessonARByID
} from "../../../backend-communication/lesson-ar-controller";
import {
  getLessonWebByID,
  saveLessonWeb
} from "../../../backend-communication/lesson-web-controller";
import { cancelRequests } from "../../../backend-communication/cancel-axios-token";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`
  };
}

const styles = theme => ({
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`
  },
  editor: {
    textAlign: "left",
    padding: 0
  },
  content: {
    //padding: theme.spacing(3)
  },
  appBar: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary
  },
  tabPanel: {
    width: "100%",
    padding: 0,
    margin: 0
  },
  grow: {
    flexGrow: 1
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  iconButton: {
    marginLeft: theme.spacing(1)
  }
});

class LessonCreator extends Component {
  state = {
    value: 0,
    editorValue: "",
    loading: false,
    ar: { scene: "", requiredPoints: "", completionPoints: "" },
    loadingWeb: true,
    loadingAR: true
  };

  start = () => {
    getLessonWebByID(this.props.id, (err, el) => {
      this.setState({ editorValue: el.data, loadingWeb: false });
    });

    getLessonARByID(this.props.id, (err, ar) => {
      if (!err && ar != null) this.setState({ ar, loadingAR: false });
      else
        this.setState({
          loadingAR: false,
          ar: { scene: "", requiredPoints: "", completionPoints: "" }
        });
    });
  };
  save = () => {
    if (this.state.value == 0) {
      this.saveWeb();
    }
    if (this.state.value == 1) {
      this.saveAR();
    }
  };

  handleARTextChange = (e, variable) => {
    let ar = { ...this.state.ar };
    ar[variable] = e.target.value;
    this.setState({ ar });
  };

  saveWeb = () => {
    this.setState({ loading: true });
    saveLessonWeb({ id: this.props.id, data: this.state.editorValue }, err => {
      this.setState({ loading: false });
    });
  };

  saveAR = () => {
    this.setState({ loading: true });
    saveLessonAR({ ...this.state.ar, id: this.props.id }, err => {
      this.setState({ loading: false });
    });
  };

  handleChange = (event, newValue) => {
    this.setState({ value: newValue });
  };

  handleEditorChange = value => {
    this.setState({
      editorValue: value
    });
  };

  componentWillUnmount() {
    cancelRequests("unmounting");
  }

  render() {
    const { classes, name } = this.props;
    const { value, editorValue, loading } = this.state;

    return (
      <SignedRoute role="public" executeOnValid={this.start}>
        <CssBaseline />
        <Paper square elevation={0}>
          <AppBar position="static" className={classes.appBar} elevation={0}>
            <Toolbar>
              <Tabs
                value={value}
                onChange={this.handleChange}
                aria-label="simple tabs example"
              >
                <Tab label="Web" />
                <Tab label="AR" />
              </Tabs>

              <div className={classes.grow} />
              {this.state.value == 0 && (
                <Button
                  className={classes.button}
                  aria-label="save"
                  color={"primary"}
                  variant={"outlined"}
                  onClick={this.save}
                  disabled={loading}
                >
                  Guardar
                  <SaveOutlined className={classes.iconButton} />
                </Button>
              )}
            </Toolbar>
          </AppBar>
        </Paper>
        <TabPanel value={value} index={0} className={classes.tabPanel}>
          {this.state.loadingWeb ? (
            this.renderLoading()
          ) : (
            <Editor
              id={"editor_markdown"}
              className={classes.editor}
              placeholder={"Write your lesson..."}
              value={editorValue}
              onChange={this.handleEditorChange}
              options={{
                hideIcons: ["side-by-side", "fullscreen"],
                spellChecker: false
              }}
            />
          )}
        </TabPanel>
        <TabPanel value={value} index={1} className={classes.tabPanel}>
          {this.state.loadingAR ? (
            this.renderLoading()
          ) : (
            <form style={{ alignItems: "center", alignContent: "center" }}>
              <TextField
                required
                type="text"
                autoComplete="off"
                id="scene"
                label="Scene"
                className={classes.textField}
                margin="normal"
                variant="filled"
                autoFocus
                value={this.state.ar.scene}
                onChange={e => this.handleARTextChange(e, "scene")}
              />
              <br />
              <TextField
                required
                type="number"
                autoComplete="off"
                id="required-points"
                label="Required Points"
                className={classes.textField}
                margin="normal"
                variant="filled"
                value={this.state.ar.requiredPoints}
                onChange={e => this.handleARTextChange(e, "requiredPoints")}
              />
              <br />
              <TextField
                required
                type="number"
                autoComplete="off"
                id="completion-points"
                label="Completion Points"
                className={classes.textField}
                margin="normal"
                variant="filled"
                value={this.state.ar.completionPoints}
                onChange={e => this.handleARTextChange(e, "completionPoints")}
              />
              <br />
              <br />
              <Button
                className={classes.button}
                aria-label="save"
                color={"primary"}
                variant={"outlined"}
                onClick={this.save}
                disabled={loading}
                size="large"
              >
                Save
                <SaveOutlined className={classes.iconButton} />
              </Button>
            </form>
          )}
        </TabPanel>
      </SignedRoute>
    );
  }

  renderLoading() {
    return (
      <React.Fragment>
        <div
          style={{
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)"
          }}
        >
          <CircularProgress />
        </div>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(LessonCreator);
