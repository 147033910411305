import axios from "axios";
import { getCancelToken } from "../../../../backend-communication/cancel-axios-token";

export function getData(filters, callback) {
  var rows = [];
  axios
    .get("/api/getAllCourses", {
      params: { filters: filters },
      cancelToken: getCancelToken().token
    })
    .then(res => {
      if (res.data.err == null) {
        res.data.data.forEach(el => {
          rows.push({
            id: el.id,
            subject: el.subject,
            subjectID: el.subjectID,
            course: el.course,
            imageURL: el.imageURL
          });
        });
        callback(rows);
      }
    });
}

export function addData(el, callback) {
  axios
    .post(
      "/api/addCourse",
      { course: el },
      { cancelToken: getCancelToken().token }
    )
    .then(res => {
      callback({
        id: res.data.data,
        course: el.course,
        imageURL: el.imageURL,
        subject: el.subject,
        subjectID: el.subjectID
      });
    });
}

export function editData(id, el, callback) {
  axios
    .put(
      `/api/editCourse/${id}`,
      { course: el },
      { cancelToken: getCancelToken() }
    )
    .then(res => {
      alert("test");
      callback({
        id: el.id,
        course: el.course,
        subjectID: el.subjectID,
        subject: el.subject,
        imageURL: el.imageURL
      });
    });
}

export function getSubjectLabel(id, subjects, callback) {
  var elementPos = subjects.findIndex(el => {
    return el.id === id;
  });
  return subjects[elementPos].subject;
}
