import React, { Component } from "react";
import PublicMain from "../public/main/publicMain";
import Container from "../about/container/container";
import AuthMngr from "../login/authmngr";

class PageContainer extends Component {
  state = { role: "guest", loading: true };
  render() {
    const { role = "guest" } = this.state;
    return this.renderDrawer(role);
  }

  _loginEvent = idToken => {
    if (idToken == null) {
      this.setState({ role: "guest", loading: false });
    } else {
      this.setState({ role: "public", loading: false });
    }
  };

  componentDidMount() {
    AuthMngr.getInstance().subscribeEvent(this._loginEvent);
  }

  componentWillUnmount() {
    AuthMngr.getInstance().unsubscribeEvent(this._loginEvent);
  }

  renderDrawer(role) {
    if (this.state.loading) return <React.Fragment />;

    if (role == null || role === "guest") {
      return <Container>{this.props.children}</Container>;
    }

    if (role === "public" || role === "admin") {
      return <PublicMain blockToolbar={true}>{this.props.children}</PublicMain>;
    }
  }
}

export default PageContainer;
