import axios from "axios";
import { networkInterfaces } from "os";
var dateFormat = require("dateformat");

export function addData(el, callback) {
  axios.post("/api/createClassroom", { classroom: el }).then(res => {
    console.log(res);
    callback(res.data.err, { ...el, id: res.data.data });
  });
}

export function getData(el, callback) {
  var rows = [];
  axios.get("/api/ownedClassrooms").then(res => {
    if (res.data.err == null) {
      res.data.data.forEach(el => {
        rows.push({
          id: el.id,
          name: el.name,
          owner: el.owner,
          imageURL: el.imageURL,
          creationDate: dateFormat(el.creationDate, "dd/mm/yyyy")
        });
      });
      callback(rows);
    }
  });
}

export function editData(id, el, callback) {
  axios.put(`/api/editClassroom/${id}`, { classroom: el }).then(res => {
    callback({
      id: el.id,
      name: el.name,
      imageURL: el.imageURL,
      creationDate: el.creationDate
    });
  });
}
