import React from "react";
import ReactDOM from "react-dom";
import firebase from "firebase";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

/*
if (!process.env.NODE_ENV || process.env.NODE_ENV !== "production") {
  console.log("dot env client");
  require("dotenv").config();
}

firebase.initializeApp({
  apiKey: process.env.FIREBASE_APP_APIKEY,
  authDomain: process.env.FIREBASE_APP_AUTHDOMAIN,
  databaseURL: process.env.FIREBASE_APP_DATABASEURL,
  projectId: process.env.FIREBASE_APP_PROJECTID,
  storageBucket: process.env.FIREBASE_APP_STORAGEBUCKET,
  messagingSenderId: process.env.FIREBASE_APP_MESSAGINGSENDERID
});
*/

firebase.initializeApp({
  apiKey: "AIzaSyBSa9W2BxkMoFT5qgKaT3CjmsJ-2ERPc0I",
  authDomain: "babel-c7c5a.firebaseapp.com",
  databaseURL: "https://babel-c7c5a-default-rtdb.firebaseio.com",
  projectId: "babel-c7c5a",
  storageBucket: "babel-c7c5a.appspot.com",
  messagingSenderId: "369486487657"
});

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
