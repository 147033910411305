import React, { Component } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import styles from "./classroom-styles";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Leaderboard from "../../../common/leaderboard/leaderboard";
import ComplexCard from "../../../common/complexCard/complexCard";
import {
  GridList,
  GridListTile,
  IconButton,
  Button,
  Fab,
  withWidth,
  DialogContentText,
  DialogTitle,
  Dialog,
  DialogContent,
  Slide,
  Toolbar,
  AppBar,
  Divider,
  DialogActions
} from "@material-ui/core";
import PublicMain from "../../main/publicMain";
import SignedRoute from "../../../common/signedRoute";
import { emptyURL, imageURL } from "../../../common/imageExits";
import history from "../../../common/history";
import {
  getClassroomById,
  getLeaderboard,
  publishLesson,
  getClassroomLessons,
  imTheClassroomOwner,
  removeLessonFromClassroom
} from "../../../../backend-communication/classroom-controller";

import { cancelRequests } from "../../../../backend-communication/cancel-axios-token";

import { getMyLessons } from "../../../../backend-communication/lessons-controller";
import { getLessonWebByID } from "../../../../backend-communication/lesson-web-controller";
import Skeleton from "@material-ui/lab/Skeleton";
import {
  WebAssetOutlined,
  PublishTwoTone,
  AndroidOutlined,
  AdbOutlined,
  ArrowBackRounded,
  EuroSymbol,
  DeleteForever,
  Remove,
  DeleteOutline
} from "@material-ui/icons";
import { isWidthUp } from "@material-ui/core/withWidth";
import CardManagement from "../../../common/dynamicCardManagement/cardManagement";
import DialogCreator from "../../../common/dialog/dialogCreator";
import DialogsInfo from "../../../common/dialogsInfo";
import { MDRenderer } from "../../../docs/md-renderer";
import { isMobile } from "mobile-device-detect";
const queryString = require("query-string");
const dialogs = new DialogsInfo();

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class Classroom extends Component {
  state = {
    classroom: { name: "", imageURL: "" },
    lessons: [],
    leaderboard: [],
    myLessons: [],
    loadingCoverPage: true,
    loadingLesson: true,
    loadingLeaderboard: true,
    loadingPublish: false,
    openDialog: false,
    dialogOpen: false,
    lessonWebOpen: false,
    currentLessonWeb: { id: "", data: "" },
    currentLessonName: "",
    currentLessonId: "",
    imTheOwner: false,
    deleteOpen: false,
    classroomId: ""
  };

  openDialog = () => {
    this.setState({ dialogOpen: true });
  };

  closeDialog = () => {
    this.setState({ dialogOpen: false });
  };

  handleWebOpen = () => {
    this.setState({ lessonWebOpen: true });
  };

  handleWebClose = () => {
    this.setState({ lessonWebOpen: false });
  };

  getGridListCols = () => {
    if (isWidthUp("xl", this.props.width)) {
      return 5;
    }

    if (isWidthUp("lg", this.props.width)) {
      return 4;
    }

    if (isWidthUp("md", this.props.width)) {
      return 2;
    }

    return 1;
  };

  start = () => {
    const query = queryString.parse(this.props.location.search);
    this.setState({ loadingCoverPage: true });
    getClassroomById(query.id, (err, el) => {
      if (err) {
        history.push("/404");
      } else this.setState({ classroom: el, loadingCoverPage: false });
    });

    this.setState({ classroomId: query.id });
    this.getLessons(query.id);
    this.getMyLessons();
    this.getLeaderBoard(query.id);
    imTheClassroomOwner(query.id, val => {
      this.setState({ imTheOwner: val });
    });
  };

  getMyLessons = () => {
    getMyLessons(null, (err, myLessons) => {
      this.setState({ myLessons });
    });
  };

  componentWillUnmount() {
    cancelRequests("unmounting");
  }

  getLessons = classroomId => {
    this.setState(
      {
        lessons: new Array(3).fill(""),
        loadingLesson: true
      },
      () => {
        getClassroomLessons(classroomId, (err, lessons) => {
          this.setState({ lessons: err ? [] : lessons, loadingLesson: false });
        });
      }
    );
  };

  getLeaderBoard = classroomId => {
    this.setState(
      {
        leaderboard: [],
        loadingLeaderboard: true
      },
      () => {
        getLeaderboard(classroomId, (err, leaderboard) => {
          this.setState({ leaderboard, loadingLeaderboard: false });
        });
      }
    );
  };

  publishLesson = (classroomId, lessonId, lesson) => {
    this.setState({ loadingPublish: true }, () => {
      publishLesson(classroomId, lessonId, err => {
        if (!err) {
          this.getLessons(classroomId);
          //const data = [lesson, ...this.state.lessons];
          //this.setState({ lessons: data });
        }
        this.setState({ loadingPublish: false });
      });
    });
  };

  render() {
    const { classes } = this.props;
    const {
      classroom,
      loadingCoverPage,
      lessons,
      loadingLesson,
      leaderboard,
      loadingLeaderboard,
      myLessons,
      loadingPublish,
      lessonWebOpen,
      deleteOpen,
      currentLessonId,
      classroomId
    } = this.state;

    const lessonsArray = loadingLesson
      ? [{ id: "1" }, { id: "2" }, { id: "3" }]
      : lessons;
    const name = classroom.name;
    const imageURL = classroom.imageURL;
    return (
      <React.Fragment>
        <CssBaseline />
        <SignedRoute role="public" executeOnValid={this.start}>
          <Container
            className={isMobile ? classes.containerMobile : classes.container}
          >
            <main>
              <div
                className={
                  isMobile
                    ? classes.mainFeaturedPostMobile
                    : classes.mainFeaturedPost
                }
              >
                {/* Increase the priority of the hero background image */}
                {loadingCoverPage ? (
                  <Skeleton variant="rect" className={classes.coverPage} />
                ) : (
                  <img
                    className={classes.coverPage}
                    src={imageURL}
                    alt="background"
                  />
                )}
                <div className={classes.overlay} />

                <div className={classes.mainFeaturedPostContent}>
                  <Typography
                    component="h1"
                    variant={!isMobile ? "h3" : "h4"}
                    color="inherit"
                    gutterBottom
                    unselectable={"on"}
                  >
                    {this.state.classroom.name}
                  </Typography>
                </div>
              </div>

              <Grid container spacing={5} className={classes.mainGrid}>
                <Grid item xs={12} md={9}>
                  <div className={classes.classroomActions}>
                    {this.state.imTheOwner && (
                      <Fab
                        className={classes.button}
                        size="medium"
                        onClick={this.openDialog}
                        disableTouchRipple={!loadingPublish}
                      >
                        <PublishTwoTone />
                      </Fab>
                    )}
                  </div>
                  <br />
                  <Typography
                    variant={"h5"}
                    align="left"
                    className={classes.title}
                  >
                    Lecciones
                  </Typography>
                  <br />
                  <GridList
                    cellHeight="auto"
                    cols={this.getGridListCols()}
                    spacing={6}
                  >
                    {lessonsArray.map(el => (
                      <GridListTile key={el.id} cols={1}>
                        <ComplexCard
                          id={el.id}
                          loading={loadingLesson}
                          imageURL={el.imageURL}
                          title={el.lesson}
                          subtitle={el.course}
                          actionArea={this.renderActions}
                          element={{ ...el }}
                        />
                      </GridListTile>
                    ))}
                  </GridList>
                </Grid>
                {!isMobile && (
                  <Grid item xs={12} md={3}>
                    <Leaderboard
                      imageFieldName="photoURL"
                      rows={leaderboard}
                      loading={loadingLeaderboard}
                    />
                  </Grid>
                )}
              </Grid>
            </main>

            <Dialog
              fullScreen
              open={lessonWebOpen}
              onClose={this.handleWebClose}
              TransitionComponent={Transition}
            >
              <AppBar className={classes.appBarWeb} elevation={0}>
                <Toolbar className={classes.toolbarWeb}>
                  <IconButton
                    edge="start"
                    onClick={this.handleWebClose}
                    aria-label="close"
                  >
                    <ArrowBackRounded />
                  </IconButton>
                  <Typography variant="h5" color="textPrimary">
                    {this.state.currentLessonName}
                  </Typography>
                </Toolbar>
                <Divider />
              </AppBar>
              <div className={classes.lessonContent}>
                <MDRenderer source={this.state.currentLessonWeb.data} />
              </div>
            </Dialog>

            <Dialog
              open={deleteOpen}
              onClose={this.handleDeleteDialogClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"Desea eliminar la lección?"}
              </DialogTitle>
              <DialogActions>
                <Button onClick={this.handleDeleteDialogClose} color="primary">
                  Cancelar
                </Button>
                <Button
                  onClick={() => {
                    this.removeLessonFromClassroom();
                  }}
                  color="primary"
                  autoFocus
                >
                  Eliminar
                </Button>
              </DialogActions>{" "}
            </Dialog>

            <DialogCreator
              fullWidth
              open={this.state.dialogOpen && !loadingPublish}
              handleClose={this.closeDialog}
              handleAction={this.state.dialogAction}
              dialogInfo={dialogs.closeDialog("Publica tus lecciones")}
              dialogContent={() => (
                <CardManagement
                  rows={myLessons}
                  xl={3}
                  lg={3}
                  md={2}
                  sm={1}
                  titleFieldName={"lesson"}
                  thumbnailFieldName={"imageURL"}
                  onClickCard={el => {
                    const query = queryString.parse(this.props.location.search);
                    this.setState({ dialogOpen: false }, () => {
                      this.publishLesson(query.id, el.id, el);
                    });
                  }}
                />
              )}
            />
          </Container>
        </SignedRoute>
      </React.Fragment>
    );
  }

  removeLessonFromClassroom() {
    const { classroomId, currentLessonId } = this.state;
    this.setState({ deleteOpen: false });
    removeLessonFromClassroom(classroomId, currentLessonId, () => {
      this.getLessons(classroomId);
    });
  }
  openDeleteDialog = () => {
    this.setState({ deleteOpen: true });
  };
  handleDeleteDialogClose = () => {
    this.setState({ deleteOpen: false });
  };

  renderActions = el => {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <IconButton
          aria-label="Lesson Web"
          className={classes.actionButton}
          disabled={!el.hasWeb}
          onClick={() => {
            if (!el.hasWeb) return;
            getLessonWebByID(el.id, (err, data) => {
              this.setState({
                currentLessonWeb: data,
                lessonWebOpen: true,
                currentLessonName: el.lesson
              });
            });
          }}
        >
          <WebAssetOutlined />
        </IconButton>
        <IconButton
          aria-label="Lesson AR"
          className={classes.actionButton}
          disabled={!el.hasAR}
        >
          <AdbOutlined />
        </IconButton>
        {this.state.imTheOwner && (
          <IconButton
            aria-label="Remove"
            onClick={() => {
              this.setState({ deleteOpen: true, currentLessonId: el.id });
            }}
            className={classes.actionButtonDelete}
            //disabled={!el.hasAR}
          >
            <DeleteOutline />
          </IconButton>
        )}
      </React.Fragment>
    );
  };
}

Classroom.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withWidth()(withStyles(styles)(Classroom));
