import React, { Component } from "react";
import Dashboard from "./dashboard/dashboard";
import history from "../common/history";
import AuthMngr from "../login/authmngr";
import SignedRoute from "../common/signedRoute";

class HomeAdmin extends Component {
  state = { loading: true };

  signOut() {
    AuthMngr.getInstance().signOut(() => {
      history.push("/");
    });
  }
  render() {
    return (
      <SignedRoute
        role="admin"
        executeOnValid={() => {
          this.setState({
            loading: false
          });
        }}
      >
        {!this.state.loading && (
          <Dashboard
            logout={() => {
              this.signOut();
            }}
          >
            {this.props.children}
          </Dashboard>
        )}
      </SignedRoute>
    );
  }
}

export default HomeAdmin;
