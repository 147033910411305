import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import CssBaseline from "@material-ui/core/CssBaseline";
import CardActionArea from "@material-ui/core/CardActionArea";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import { getData, getCourses, getSubjects } from "./navigateLessons-controller";
import styles from "./naviagateLessons-style";
import Searchbar from "../../common/overrides/searchBar/searchbar";
import Dialog from "@material-ui/core/Dialog";
import { Parallax } from "react-parallax";
import { isMobile } from "mobile-device-detect";
import history from "../../common/history";
import FilterDialog from "../../common/filter/filterDialog";
import FilterSelect from "../../common/filter/filters/filter-select";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import { MDRenderer } from "../../docs/md-renderer";
import { getMarkdown } from "../../docs/docs-controller";
import { CardContent } from "@material-ui/core";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class NavigateLesson extends Component {
  state = {
    rows: [],
    dialogOpen: false,
    el: {},
    filterOpen: false,
    courses: [],
    subjects: [],
    filters: [],
    extraFilters: [],
    userPoints: -1,
    searchVal: ""
  };

  constructor(props) {
    super(props);
    const refs = [];
    this.courseID = React.createRef();
    refs.push(this.courseID);
    this.state.refs = refs;
  }

  componentDidMount() {
    this.getData();
    this.getCourses();
    this.getSubjects();
  }

  getData = filters => {
    getData(filters, rows => {
      this.setState({ rows });
    });
  };

  getCourses = filters => {
    getCourses(filters, courses => {
      this.setState({ courses });
    });
  };

  getSubjects = filters => {
    getSubjects(filters, subjects => {
      this.setState({ subjects });
    });
  };

  handleClose = () => {
    this.setState({ dialogOpen: false });
  };

  handleOpen = card => {
    getMarkdown("babelweb", res => {
      this.setState({ currentDoc: res });
    });
    this.setState({ dialogOpen: true, el: card });
  };

  filterOpen = () => {
    this.setState({ filterOpen: true });
  };

  filterClose = () => {
    this.setState({ filterOpen: false });
  };

  getFilters = filters => {
    this.setState({ filters });
  };

  filter = filters => {
    this.getData(filters);
  };

  changeSearchVal = event => {
    let extraFilters = [];
    let filter = { type: "text", id: "l.lesson", val: event.target.value };
    extraFilters.push(filter);
    this.setState({ searchVal: event.target.value, extraFilters });
  };

  render() {
    const { classes } = this.props;
    const { dialogOpen } = this.state;
    return (
      <React.Fragment>
        <CssBaseline />

        <main className={classes.main}>
          {/* Hero unit */}
          <Parallax
            blur={5}
            bgImage={"https://imgur.com/3FBBU3S.jpg"}
            bgImageAlt="the cat"
            strength={600}
          >
            <div style={{ height: "150px" }} />
            <div
              className={
                isMobile ? classes.heroContentMobile : classes.heroContent
              }
            >
              <Typography
                component="h1"
                variant="h3"
                align="center"
                className={classes.mainTitle}
                gutterBottom
              >
                Look for a lesson
              </Typography>
              <Searchbar
                value={this.state.searchVal}
                onSearch={() => {
                  this.filter([
                    ...this.state.filters,
                    ...this.state.extraFilters
                  ]);
                }}
                onChange={this.changeSearchVal}
                filterAction={() => {
                  this.setState({ filterOpen: true });
                }}
              />

              <div style={{ height: "150px" }} />
            </div>
          </Parallax>
          {this.renderLessons(classes)}
        </main>
        {/* Footer */}
        <footer className={classes.footer}>
          <Typography
            variant="h6"
            align="center"
            gutterBottom
            className={classes.footerFont}
            onClick={() => {
              history.push("/signed/downloads");
            }}
          >
            Babel
          </Typography>
          <Typography
            variant="subtitle1"
            align="center"
            color="textSecondary"
            component="p"
            className={classes.footerFont}
          >
            Contact: babel-service@gmail.com
          </Typography>
        </footer>
        {/* End footer */}
        <Dialog
          fullScreen
          open={dialogOpen}
          onClose={this.handleClose}
          TransitionComponent={Transition}
        >
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={this.handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography variant="h5">{this.state.el.lesson}</Typography>
            </Toolbar>
          </AppBar>
          <div className={classes.lessonContent}>
            <MDRenderer source={this.state.currentDoc} />
          </div>
        </Dialog>

        <FilterDialog
          refs={this.state.refs}
          handleClose={this.filterClose}
          open={this.state.filterOpen}
          filter={this.filter}
          extraFilters={this.state.extraFilters}
          getFilters={this.getFilters}
        >
          <FilterSelect
            label="Course"
            ref={this.courseID}
            id={"l.courseID"}
            keyName="id"
            valueName="course"
            values={this.state.courses}
          />
        </FilterDialog>
      </React.Fragment>
    );
  }

  renderLessons(classes) {
    if (!isMobile)
      return (
        <div className={classNames(classes.layout, classes.cardGrid)}>
          {/* End hero unit */}
          <Grid container spacing={2}>
            {this.state.rows.map(card => (
              <Grid item key={card.id} sm={6} md={4} lg={4}>
                {this.renderCard(classes, card)}
              </Grid>
            ))}
          </Grid>
        </div>
      );
    else {
      return (
        <div className={classes.mobileContainer}>
          {this.state.rows.map(card => (
            <Grid item key={card.id} sm={6} md={4} lg={4}>
              {this.renderCard(classes, card)}
            </Grid>
          ))}
        </div>
      );
    }
  }

  renderCard(classes, card) {
    return (
      <Card className={isMobile ? classes.cardMobile : classes.card}>
        <CardActionArea
          onClick={() => {
            this.handleOpen(card);
          }}
        >
          <CardMedia
            className={classes.cardMedia}
            image={card.imageURL}
            title={card.lesson}
          ></CardMedia>
          <CardContent>
            <Typography
              gutterBottom
              variant="h5"
              component="h2"
              color="textSecondary"
            >
              {card.lesson}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              {card.course}
            </Typography>
          </CardContent>
        </CardActionArea>
        {/*<CardHeader
          avatar={<Chip label={card.type} className={classes.chip} />}
          title={card.lesson}
          className={classes.cardHeader}
          classes={{
            title: classes.title
          }}
        />        <CardActions className={classes.actions} disableActionSpacing>
          <Button
            size="large"
            className={classes.cardButton}
            onClick={() => {
              this.handleOpen(card);
            }}
          >
            Learn
          </Button>
        </CardActions>*/}
      </Card>
    );
  }
}

NavigateLesson.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(NavigateLesson);
