import React, { Component } from "react";
import SignedRoute from "../../common/signedRoute";
import {
  IconButton,
  Typography,
  TextField,
  Tooltip,
  Dialog,
  Slide as SlideMaterial,
  Toolbar,
  AppBar,
  Divider,
  Button
} from "@material-ui/core";
import {
  AddCircleOutline,
  EditOutlined,
  DeleteOutline,
  MoreHorizOutlined,
  VisibilityOutlined,
  ArrowBackRounded
} from "@material-ui/icons";

import {
  getCourseLabel,
  addData,
  editData,
  getMyLessons
} from "../../../backend-communication/lessons-controller";

import { getData as getCourses } from "../../admin/lessons/courses/courses-controller";

import DialogCreator from "../../common/dialog/dialogCreator";
import DialogsInfo from "../../common/dialogsInfo";
import CardManagement from "../../common/dynamicCardManagement/cardManagement";
import styles from "./home-styles";
import { withStyles } from "@material-ui/styles";
import AutoFitImage from "react-image-autofit-frame";
import SelectTextfield from "../../common/autocomplete/select/selectTextfield";

import history from "../../common/history";
import { cancelRequests } from "../../../backend-communication/cancel-axios-token";
import {
  getVerifiedClasrooms,
  getInvitation
} from "../../../backend-communication/classroom-controller";
import { getARVerified } from "../../../backend-communication/lesson-ar-controller";
import {
  getWebVerified,
  getLessonWebByID
} from "../../../backend-communication/lesson-web-controller";
import { MDRenderer } from "../../docs/md-renderer";
import { Swiper, Slide } from "react-dynamic-swiper";
import "react-dynamic-swiper/lib/styles.css";

const dialogs = new DialogsInfo();

const Transition = React.forwardRef(function Transition(props, ref) {
  return <SlideMaterial direction="up" ref={ref} {...props} />;
});

const imageData = [
  {
    src: "https://placeimg.com/640/480/any",
    title: "Image 1"
  },
  {
    src: "https://placeimg.com/640/480/any",
    title: "Image 2"
  }
];

class Home extends Component {
  state = {
    verifiedClassrooms: [],
    verifiedAR: [],
    verifiedWeb: [],
    loadingClassrooms: true,
    loadingAR: true,
    loadingWeb: true,
    lessonWebOpen: false,
    currentLessonWeb: "",
    slides: [
      "https://placeimg.com/640/480/any",
      "https://placeimg.com/640/480/any",
      "https://placeimg.com/640/480/any"
    ]
  };

  start = () => {
    getVerifiedClasrooms(null, (err, verifiedClassrooms) => {
      this.setState({ verifiedClassrooms, loadingClassrooms: false });
    });

    /*getARVerified(null, (err, verifiedAR) => {
      this.setState({ verifiedAR, loadingAR: false });
    });*/
    /*getWebVerified(null, (err, verifiedWeb) => {
        this.setState({ verifiedWeb, loadingWeb: false });
    });*/
  };

  handleWebOpen = () => {
    this.setState({ lessonWebOpen: true });
  };

  handleWebClose = () => {
    this.setState({ lessonWebOpen: false });
  };

  handleClassroomClick = el => {
    history.push({
      pathname: "joinClassroom",
      search: `?id=${el.id}`,
      state: { name: el.name }
    });
  };

  handleWebClick = el => {
    getLessonWebByID(el.id, (err, data) => {
      this.setState({
        currentLessonWeb: data.data,
        lessonWebOpen: true,
        currentLessonName: el.lesson
      });
    });
  };

  componentWillUnmount() {
    cancelRequests("unmount");
  }

  render() {
    const { classes } = this.props;
    const {
      verifiedClassrooms,
      loadingClassrooms,
      verifiedAR,
      loadingAR,
      verifiedWeb,
      loadingWeb,
      lessonWebOpen,
      currentLessonWeb,
      slides
    } = this.state;

    return (
      <React.Fragment>
        <SignedRoute role="public" executeOnValid={this.start}>
          <main className={classes.content}>
            <Typography variant="h6" align={"left"} className={classes.title}>
              Salones Verificados
            </Typography>

            <CardManagement
              cols={5}
              titleFieldName={"name"}
              thumbnailFieldName={"imageURL"}
              rows={verifiedClassrooms}
              onClickCard={this.handleClassroomClick}
              loading={loadingClassrooms}
            />
            <br />
            <br />
            {/*<Typography variant="h6" align={"left"} className={classes.title}>
              Verified Web Lessons
            </Typography>
            <CardManagement
              cols={5}
              titleFieldName={"lesson"}
              thumbnailFieldName={"imageURL"}
              rows={verifiedWeb}
              onClickCard={this.handleWebClick}
              loading={loadingWeb}
            />
            <br />
            <br />
            {/**
            <Typography variant="h5" align={"left"} className={classes.title}>
              AR Lessons
            </Typography>
            <CardManagement
              cols={5}
              titleFieldName={"lesson"}
              thumbnailFieldName={"imageURL"}
              rows={verifiedAR}
              // cardActions={this.renderExtras}

              loading={loadingAR}
            />
           */}
          </main>

          <Dialog
            fullScreen
            open={lessonWebOpen}
            onClose={this.handleWebClose}
            TransitionComponent={Transition}
          >
            <AppBar className={classes.appBarWeb} elevation={0}>
              <Toolbar className={classes.toolbarWeb}>
                <IconButton
                  edge="start"
                  onClick={this.handleWebClose}
                  aria-label="close"
                >
                  <ArrowBackRounded />
                </IconButton>
                <Typography variant="h5" color="textPrimary">
                  {this.state.currentLessonName}
                </Typography>
              </Toolbar>
              <Divider />
            </AppBar>
            <div className={classes.lessonContent}>
              <MDRenderer source={this.state.currentLessonWeb} />
            </div>
          </Dialog>
        </SignedRoute>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(Home);
