import axios from "axios";

export function getData(filters, callback) {
  axios
    .get("/api/getAllLessons", {
      params: { filters: filters }
    })
    .then(res => {
      if (res.data.err == null) {
        callback(res.data.data);
      }
    });
}

export function getCourses(filters, callback) {
  var rows = [];
  axios
    .get("/api/getAllCourses", {
      params: { filters: filters }
    })
    .then(res => {
      if (res.data.err == null) {
        res.data.data.forEach(el => {
          rows.push({
            id: el.id,
            subject: el.subject,
            subjectID: el.subjectID,
            course: el.course,
            imageURL: el.imageURL
          });
        });
        callback(rows);
      }
    });
}

export function getSubjects(filters, callback) {
  var rows = [];
  axios
    .get("/api/getAllSubjects", { params: { filters: filters } })
    .then(res => {
      if (res.data.err == null) {
        res.data.data.forEach(el => {
          rows.push({
            id: el.id,
            subject: el.subject,
            imageURL: el.imageURL
          });
        });
        callback(rows);
      }
    });
}
