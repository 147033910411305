class DialogsInfo {
  addDialog(name) {
    return {
      title: `Agregar ${name}`,
      content: "",
      buttons: ["Agregar", "Cancelar"]
    };
  }

  editDialog(name) {
    return {
      title: `Editar ${name}`,
      content: "",
      buttons: ["Editar", "Cancelar"]
    };
  }

  deleteDialog(name) {
    return {
      title: `Eliminar ${name}?`,
      content: "",
      buttons: ["Eliminar", "Cancelar"]
    };
  }

  genericDialog(name) {
    return {
      title: name,
      content: "",
      buttons: ["Ok"]
    };
  }

  closeDialog(name) {
    return {
      title: name,
      content: "",
      buttons: ["Cancelar"]
    };
  }

  reestablecerPass(name) {
    return {
      title: name,
      content: "Enviar link de recuepración al siguiente correo",
      buttons: ["Enviar", "Cancelar"]
    };
  }
}

export default DialogsInfo;
