import React, { Component } from "react";
import { withStyles } from "@material-ui/styles";
import lost from "../../img/lost.svg";
import { Typography, Grid, Box, Button } from "@material-ui/core";
import history from "../common/history";

import AuthMngr from "./authmngr";

const styles = theme => ({
  content: {
    backgroundColor: theme.palette.background.paper,
    //backgroundColor: "blue",
    position: "absolute",
    zIndex: 10000,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0
  }
});

class VerifyEmail extends Component {
  state = {};

  componentDidMount() {
    if (AuthMngr.getInstance().hasEmailVerification()) {
      history.push("/home");
    }
  }
  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <div className={classes.content}>
          <Box
            style={{
              padding: 0,
              margin: 0,
              justifyContent: "center",
              alignItems: "center",
              display: "flex"
            }}
            height="100%"
          >
            <Typography variant="h2" color="textSecondary">
              Último paso
              <Typography variant="h4" color="textSecondary">
                Debes verificar tu email
              </Typography>
              <br />
              <Button
                size="large"
                variant="outlined"
                color="primary"
                onClick={() => {
                  AuthMngr.getInstance().resendVerification();
                  AuthMngr.getInstance().signOut(() => {
                    history.push("home");
                  });
                }}
              >
                Reenviar Email
              </Button>
            </Typography>

            <br />
          </Box>
        </div>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(VerifyEmail);
